import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"
import router from '../router/index'
Vue.use(Vuex) 
export default new Vuex.Store({
	state: {
		imgurl:'',
		state_Sendkey: { 
		},
		islogin: { 
		// nickname
			// userToken
		},
		index:'home',
		shop_index:''
	},
	getters: {},
	mutations: {
		imgurl_fun(state, data){
			state.imgurl = data
		},
		change_shop_index(state, data){
			 
			state.shop_index = data
		},
		change_index(state, data) {
			
			state.index = data
			 
		},
		UPDATA_state_Sendkey(state, data) { 
			state.state_Sendkey = data
		},
		UPDATA_login(state, data) { 
			 
			state.islogin = data
		},
		UPDATA_out(state, data) {  
			state.islogin = []
			router.push({
					name: 'home'
				})
			
		},
		changename(state, data){
			state.islogin.nickname = data
		}
	},
	actions: {},
	modules: {},
	plugins: [createPersistedState({
		storage: window.sessionStorage
	})]
})
