<template>
	<div class="index-foot"  style="min-width: 1600px;">
		<div class="foot-body" style="border-top: 1px solid rgba(33, 33, 33, 0.1);">
			<div class="icon" >
				<img :src="url+dataa.logo" style="width: 135px;height: 80px;filter: brightness(0) invert(1)" ></img>
			</div>
			
			<div class="sub" v-for="(item,index) in footMenu">
				<div class="title">{{item.parent_title}}</div>
				<div class="title2" v-for="(it,i) in item.sonArr" @click="asd(it,i)">
				{{it.son_title}}</div>
			</div> 
			<div class="sub">
				<div class="title">服务热线</div>
				<div class="title2" style="margin: 40px 0;"> 
						<h4 style="font-size:30px;color: #fff;font-weight: bold">{{dataa.tel}}</h4>
				</div>
				<div class="flex" >
					<div class="title2-img">
						<img :src="url+ dataa.qrcode"></img>
						<div style="padding-top: 8px; color: #fff;">{{dataa.qrcodetips}}</div>
					</div>
					<div class="title2-img">
						<img :src="url+ dataa.wxservice">
						</img>
						<div style="padding-top: 8px; color: #fff;" >{{dataa.wxservicetips}}</div>
					</div>
				</div>
				<!-- <div style="margin: 16px 0;font-size:14px;color: #3a3a3a; font-weight:bold;color: #fff;">
				<span style="font-size:17px">{{dataa.up_time}}</span></div> -->
				<!-- <div style="position: relative;">
					<button style="margin-top: 16px;
					text-align: center;
					width: 128px;
					height: 34px;
					font-weight: 600;
					font-size: 12px;
					line-height: 34px;
					color: #fff;
					background: #3e60ee;
					border-radius: 2px!important;
					border-color: transparent!important;position: absolute;">联系我们</button>
				</div> -->
			</div> 
			<div class="Copyright" >
				{{dataa.copyright}}  <a style="color: #6a707d;" :href="dataa.prepare_url ? dataa.prepare_url:'javascript:;'" :target="dataa.prepare_url ? '_blank' : '' ">{{dataa.prepare}}</a>
				<a style="color: #6a707d;" :href="dataa.cop_prepare_url ? dataa.cop_prepare_url: 'javascript:;'" :target="dataa.cop_prepare_url ? '_blank' : ''">{{dataa.cop_prepare}}</a>
				
				 
				<!-- Copyright © 2009-2020 广州威尔森信息科技有限公司 All rights reserved. | 粤ICP备09206296号 | 粤公网安备44010602007075号 -->
			</div>
		</div>
	</div>
</template>
<script> 
	export default { 
		 props: ['url','dataa','footMenu'],
		data() {
			return { 
				a:[
					'汽车销量',
					'新能源销量',
					'汽车保有量',
					'新车成交价',
					'车型库',
					'汽车行业报告',
				],
				b:[
					'新能源汽车',
					'市场销售',
					'智能网联',
					'新车上市',
					'新能源供应链',
					'汽车投融资',
				],
				c:[
					'数据超市',
					'API产品',
					'行业报告',
					'行业动态',
					'行业动态',
					'关于我们',
				]
			
			
			
			}
		},
		mounted() { },
		methods: { 
			asd(it,i){  
				
				if(it.son_url.startsWith("info")){
					let b = it.son_url.split("=")
					 this.$router.push({
					     name:'info',
					 	params: {id:b[1]}
					 })
					 return 
				}
				if(it.son_url.startsWith("report")){
					let b = it.son_url.split("=")
					 this.$router.push({
					     name:'report',
					 	params: {id:b[1]}
					 })
					 return 
				}
				
				
				this.$router.push({
				    name:'shop',
					params: {url:it.son_url}
				})
			}
			
		},
	}
</script>

<style lang="scss" scoped>
	.flex{
		display: flex;
	}
	.index-foot {position: absolute;
	z-index: 20;
		width: 100%;
		height: 440px; 
		background-color: #222328; 
		.foot-body {
			.Copyright {
				cursor: pointer;
				border-top: 1px solid rgba(255, 255, 255, 0.1);
				width: 100%;
				height: 64px;
				padding: 24px 0;
				font-size: 12px;
				line-height: 16px;
				color: #6a707d;
				 
				text-align: center;
				position: absolute;
				bottom: 0;
			}

			position: relative;
			padding: 48px 0 0;
			display: flex;
			justify-content: space-between;
			width: 70vw;
			min-width: 1200px;
			margin: 0 auto;
			height: 440px;

			.sub {
				.title {
					font-weight: 600;
					font-size: 18px;
					line-height: 20px;
					margin-bottom: 24px;
					    color: #fff;
				}

				.title2 {
					font-size: 14px;
					line-height: 20px;
					margin-bottom: 16px;
					cursor: pointer;
					color: #6a717d;
				}

				.title2-img {
					font-size: 12px;
					color: #464a53;
					margin-right: 20px;
					text-align: center;

					img {

						height: 112px;
						width: 112px;
					}
				}

				width: 19%;
				color: white;
			}

			.icon {
				width: 19%;
				height: 68px;

				img {
					width: 216px;
					height: 68px;
				}
			}
		}
	}
 
</style>
