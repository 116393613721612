
/**
 * 工具类
 */
import Vue from 'vue'
import CryptoJS from 'crypto-js'
 
function  RandomId(n){ 
  let str = ['0','1','2','3','4','5','6','7','8','9','A','B','C','D','E','F','G','H','I','J','K',
   'L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];
  let res = '';
  for (let i = 0; i < n; i++) {
    let id = Math.ceil(Math.random() * 35);
    res += str[id];
  }
  return res;

} 
 
 
 
//加密
export function aes_encrypt(word){  
	if(word==''){
		word = 1
	}
	 let keyStr = RandomId(16);//加密密钥
	 let ivStr  = RandomId(16);//矢量
     
    let key  = CryptoJS.enc.Utf8.parse(keyStr);
	let iv   = CryptoJS.enc.Utf8.parse(ivStr);
    // var srcs = CryptoJS.enc.Utf8.parse(word);
    let encrypted = CryptoJS.AES.encrypt(word, key, {
		iv:iv,
		mode:CryptoJS.mode.CBC,
		padding: CryptoJS.pad.Pkcs7});
		 
		 
	 let a={ 
		 kStr:keyStr,
		 iStr:ivStr,
		 enc:encrypted.toString()
		 
	 }
	  
    return a;
}
 
//解密  
export function aes_decrypt(word,keyStr,ivStr){  
 
    let key  = CryptoJS.enc.Utf8.parse(keyStr);
	let iv   = CryptoJS.enc.Utf8.parse(ivStr);
	 
	 
    let restoreBase64=word.replace(/\\/g,'')
    let decrypt = CryptoJS.AES.decrypt(restoreBase64, key, {
		iv:iv,
		mode:CryptoJS.mode.CBC,
		padding: CryptoJS.pad.Pkcs7}); 
		 
		 
    // return CryptoJS.enc.Utf8.stringify(decrypt).toString();
	return CryptoJS.enc.Utf8.stringify(decrypt).toString();
}
 