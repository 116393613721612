

import axios from 'axios'
import state from '@/store/index'
import Vue from 'vue'
import router from 'vue-router'
//创建一个axios  
let server = axios.create({ 
	// 请求公共地址
    baseURL: "",
	// apilist
	// http://qcsys.cnrealhot.com
	// baseURL: "", 
    // 超时时间
    // timeout: 3000, 
	headers: { // 头部信息
		'Access-Control-Allow-Origin': '*', 
		"Content-Security-Policy": "upgrade-insecure-requests",
	 'Content-Type': 'application/x-www-form-urlencoded'
	}, 
}) 
// 添加请求拦截器
server.interceptors.request.use((config)=>{
    // 在发送请求之前做些什么
    // 比如判断是否有token 
    return config;
  }, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  });
 
// 添加响应拦截器
server.interceptors.response.use((response)=>{
    // 对响应数据做点什么 
	 
	 
	if(response.data.status == -10){ 

		state.commit('UPDATA_out','')    
		Vue.prototype.$message.info(response.data.msg )  
		return response.data
	}
    return response.data;
  }, function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
  });
 
export default server