<template>
	<div class="home-body" >


		<div class="swiper-container" style="min-height: 200px;">
			<div class="swiper-wrapper">
				<div class="swiper-slide  " v-for="(item,index) in slideData">
					<img :src="item.files" style="width: 100%;height: auto;">
				</div>
			</div>

		</div>

		<div class="home-body-head">
			<!-- <div @mouseenter="onMouseOver2()" @mouseleave="onMouseOut()" class="left-show" :style="{width:DDD+'px'}"
	   			v-show="isShow">
	   			<div v-for="(item3,index3) in  showData" class="showData">
	   				<div class="center">
	   					<div class="center-title">{{item3.son_title}}</div>
	   					<div class="center-title2">{{item3.son_intro}}</div>
	   				</div>
	   			</div>
	   		</div> -->
			<!-- <div>
	   			<div class="left" v-for="(item,index) in supermarketMenu" @mouseenter="onMouseOver(index)"
	   				@mouseleave="onMouseOut()">
	   				<div class="left-title">
	   					<div class="cuIcon cuIcon-right "
	   						style="position: absolute;right: 10px;top: 50%;transform: translateY(-50%);font-size: 6px;color: #b8b8b8;">
	   					</div>
	   					<div class="left-title-view">
	   						<div class="title">
	   							{{item.parent_title}}
	   						</div>
	   						 
	   					</div>
	   				</div>
	   			</div>
	   		</div> -->

			<div>
				<!-- <v-carousel :slideData="slideData"    :begin="0" :interval="5000" :dot="false" :arrow="false"></v-carousel> -->
			</div>
		</div>


		<!-- 行业报告 v-if="report.title"-->
		<div class="hybgw" style="min-height: 500px;">


			<div class="hybg">
				<div style="width: 100%;display: flex; 
	   				justify-content: space-between;
	   				align-items: center;">
					<div class="Headline">
						<div class="Headline-t" style="color: #2c3e50;">{{report.title}}</div>
						<div class="Headline-t2" style="    color: #7f7f7f;">{{report.intro}}</div>
					</div>
					<!-- <div style="color: white;">
	   					查看更多<span class="cuIcon cuIcon-right"></span>
	   				</div> -->
				</div>

				<div class="hybg-car">
					<div class="car" v-for="(item,i) in report.data" @click="subhybg(item,i)" :key="i" ref="aaa"
						@mousemove="inFun(i)" @mouseleave="outFun(i)">
						
						<div class="car-img"
						 :style="{backgroundImage: `linear-gradient(to bottom right, ${item.listColor1}, ${item.listColor2})` }"
							style="position: relative;  color: white;">
							<div class="abdivA"> 
							<img style="width: 80%;height: 80%;filter: brightness(0) invert(1);" :src="$store.state.imgurl"  >
							  </div>
							<div class="abdivB" v-show="item.kind_title">
								<div  :style="{color:item.listColor2}">{{item.kind_title}}</div>
							</div>
							<div class="abdivC"  v-show="item.listYears">{{item.listYears}}</div>
							<!-- <img :src="url + item.img" v-if="url+item.img"> -->
							<div class="abdivD"  v-show="item.listSynopsis">{{item.listSynopsis}}</div>
							
							<div class="abdivE" v-show="item.listPeriod">{{item.listPeriod}}{{item.listUnit}}</div>
							
						</div>
						<div class="car-body">
							<div class="car-about">
								<div class="car-about-top">
									{{item.title}}
								</div>
								<div class="car-about-bottom">
									<div> {{item.upload_time}} </div>
									<div> 共{{item.pagination}}页 </div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
		<!-- 行业动态 -->
		<div class="hydt-bg" v-if="news.title">
			<div class="hydt">
				<div style="height: 50px;width: 50px;"></div>
				<div class="Headline">
					<div class="Headline-t">{{news.title}}</div>
					<div class="Headline-t2">{{news.intro}}</div>
				</div>
				<div class="hydt-center" style="flex-wrap: wrap;">
					<div class=" hydt-center-car" @click="subhydt(item,i)" v-for="(item,i) in news.data" :key="i"
						ref="aaa2" @mousemove="inFun2(i)" @mouseleave="outFun2(i)">
						<div class="car-img">
							<img v-if="url+item.img" :src="url+item.img">
						</div>
						<div class="car-body3">
							<div class="car-about2">
								<div class="car-about-top">
									{{item.title}}
								</div>
								<div class="car-about-bottom">
									<div>
										{{item.intro}}
									</div>

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="dasj">
			<div class="dasj-body">
				<div class="dasj-body-top">
					<div class="dasj-body-oney" v-for="(item,index) in homesContent" :key="index">
						<div class="number">
							{{item.a}}
						</div>
						<div class="title">
							{{item.b}}
						</div>
					</div>
				</div>
				<div class="dasj-body-bottom" style="box-shadow: 0 24px 32px rgba(23 ,25 ,32, 0.04)">
					<div class="title">
						{{aboutus.title}}
					</div>
					<div class="title2">
						{{aboutus.intro}}
					</div>
					<div class="button" @click="goAbout">
						了解详情<span class="cuIcon cuIcon-right"></span>
					</div>
				</div>
			</div>
		</div>


		<div style="width: 100%;background-color:#fff" v-if="client.title">
			<div style="width: 1px;height: 1px;">
			</div>
			<div class="kehu">
				<div class="Headline2">
					<div class="Headline2-t">{{client.title}}</div>
					<div class="Headline2-t2">{{client.intro}}</div>
				</div>
				<div class="kehu-car">
					<div class="kehu-carabout" style="display: flex;flex-wrap: wrap;">
						<div v-for="(it,i) in client.data" class="for">
							<img :src="url+it.img" v-if="url+it.img"></img>
						</div>
					</div>
				</div>
			</div>
		</div>


	</div>
	</div>
</template>
<script>
	import Swiper from 'swiper'; // 注意引入的是Swiper
	import 'swiper/css/swiper.min.css' // 注意这里的引入 
	import {
		home_methods
	} from '../api/index.js' //引入页面

	import carousel from "@/components/carousel";
	// import * as echarts from 'echarts'; 

	import Header from '@/components/hhd.vue'

	export default {
		name: 'home',
		components: {
			Header,
			'v-carousel': carousel,
		},

		data() {
			return {

				client: {},
				slideData: [],
				url: '',
				supermarketMenu: [],
				news: {},
				report: {},
				homesContent: {},
				aboutus: {},
				myChartStyle: {
					float: "left",
					width: "100%",
					height: "400px"
				},
				DDD: 0,
				showData: [],
				isShow: false,

			}
		},
		mounted() {
			window.scrollTo(0, 0)
			this.$store.commit('change_index', 'home')
			this.homeFun()

		},
		methods: {
			goAbout(){
				this.$router.push({
					name:'about'
				})
			},
			subhydt(item, i) {

				this.$router.push({
					name: 'reportInfor',
					params: {
						key: item
					}
				})
			},
			subhybg(item, i) {
if(!this.$store.state.islogin.userToken){
       this.$message.warning('请先登录')
      return
}
				this.$router.push({
					name: 'report_gm',
					params: {
						key: item
					}
				})
			},
			fuff() {
				var mySwiper = new Swiper('.swiper-container', {
					autoHeight: true,
					autoplay: { //自动开始
						delay: 5000, //时间间隔
						disableOnInteraction: false, //*手动操作轮播图后不会暂停*
					},
					loop: true, // 循环模式选项

				})
			},
			homeFun() {
				home_methods({}).then(res => {
					if (res.status == 1) {

						this.url = res.data.url
						this.slideData = res.data.advertis

						for (let i = 0; i < this.slideData.length; i++) {
							this.slideData[i].files = this.url + this.slideData[i].files
						}
						this.$nextTick(() => {
							this.fuff()
						})


						this.supermarketMenu = res.data.supermarketMenu
						this.news = res.data.news
						this.report = res.data.report
						this.aboutus = res.data.aboutus
						this.client = res.data.client

						this.homesContent = [{
								a: res.data.homesContent.carreport ? res.data.homesContent.carreport : '',
								b: '汽车行业报告',
							},
							{
								a: res.data.homesContent.covercity ? res.data.homesContent.covercity : '',
								b: '覆盖城市',
							},
							{
								a: res.data.homesContent.setup_time ? res.data.homesContent.setup_time : '',
								b: '平台成立时间',
							},
							{
								a: res.data.homesContent.take_user ? res.data.homesContent.take_user : '',
								b: '订阅用户',
							},
							{
								a: res.data.homesContent.updata ? res.data.homesContent.updata : '',
								b: '持续更新数据',
							}
						]
					}

				})
				// .catch(err =>{ })
			},

			inFun2(i) {

				this.$refs.aaa2[i].children[0].childNodes[0].style.transform = "scale(1.1,1.1)";
				this.$refs.aaa2[i].children[0].childNodes[0].style.transition = "0.3s";


			},
			outFun2(i) {
				this.$refs.aaa2[i].children[0].childNodes[0].style.transform = "scale(1,1)";
			},
			inFun(i) {
				this.$refs.aaa[i].children[0].style.transform = "scale(1.1,1.1)";
				this.$refs.aaa[i].children[0].style.transition = "0.3s";
			},
			outFun(i) {
				this.$refs.aaa[i].children[0].style.transform = "scale(1,1)";
				// this.$refs.aaa[i].children[0].childNodes[0].style.transform = "scale(1,1)";
			},

			onMouseOver2() {
				this.isShow = true
			},
			onMouseOver(index) {
				this.isShow = true
				this.showData = this.supermarketMenu[index].sonArr
				if (this.showData.length > 6 && this.showData.length <= 12) {
					this.DDD = 600
				} else if (this.showData.length > 12 && this.showData.length <= 18) {
					this.DDD = 900
				} else if (this.showData.length > 12 && this.showData.length <= 24) {
					this.DDD = 1200
				} else {
					this.DDD = 300
				}

			},
			onMouseOut(index) {
				this.isShow = false
			}

		}
	}
</script>
<style lang="scss" scoped>
	.abdivA {
		width: 81px;
		height: 48px;
		 
		position: absolute;
		top: 20px;
		left: 20px;
		z-index: 222;
		 
	}

	.abdivB {
		
		background-image:   linear-gradient(to  right,rgba(253, 253, 253, 1)  , rgba(255, 255, 255, 0.5)  );
		line-height: 40px;
		text-align: center;
		transform: skewX(-155deg);
		 
		width: 120px;
		height: 40px;
		position: absolute;
		top: 20px;
		right: -20px;
		z-index: 222;

		div {
			color: red;
			transform: skewX(155deg)
		}
	}

	.abdivC {
		font-size: 28px;
		color: rgba(255, 255, 255, 0.5);
		position: absolute;
		bottom: 10px;
		left: 10px;
		z-index: 222;
	}

	.abdivD {
		font-size: 28px;
		color: rgba(255, 255, 255, 0.5);
		position: absolute;
		bottom: 10px;
		right: 10px;
		z-index: 222;
	}

	.abdivE {
		font-size:38px;
		color: rgba(255, 255, 255,1);
		position: absolute;
		top: 64px;
		left: 20px;
		z-index: 222;
	} 

	 

	.car-body3 {
		flex: 1;
		height: 82px;
		position: relative;

		.car-about2 {
			.car-about-bottom {
				font-size: 14px;
				line-height: 20px;
				text-align: justify;
				color: #5a6274;
				overflow: hidden;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 5;
				text-overflow: ellipsis;
			}

			.car-about-top {
				font-weight: 600;
				font-size: 15px;
				line-height: 18px;
				margin-bottom: 10px;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}

			position: absolute;
			left:50%;
			top:50%;
			transform: translate(-50%, -50%);
			width: 84%;
		}
	}


	.swiper-container {}

	.swiper-slide {
		text-align: center;
		line-height: 400px;
		color: #b0b0b0;
	}

	.swiper-slide1 {
		background: red;
		height: 800px;
	}

	.swiper-slide2 {
		height: 500px;
		background: blue;
	}

	.swiper-slide3 {
		height: 200px;
		background: yellow;
	}

	.modal-wrap {
		.modal-wrap-body {
			width: 200px;
			height: 200px;
			background-color: red;
		}

		background: rgba(0, 0, 0, .25);
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1000;
		overflow: auto;
		outline: 0;
		-webkit-overflow-scrolling: touch;
		z-index: 10000 !important;
	}

	.kehu {

		width: 1200px;
		margin: 0 auto;
		padding-bottom: 40px;

		.kehu-car {
			width: 100%;
			margin: 0 auto;

			.kehu-carabout {
				.for {
					// display: inline-block;
					// width: 20%;
					// height: 103.2px;
					// margin: 0 0 0.5rem 0;
					// border-radius: 4px;
					// position: relative;
					width: 224px;
					height: 88px;
					margin: 0 16px 16px 0;
					box-shadow: 0 4px 20px rgb(23 25 32 / 4%);
					border-radius: 4px;

					img {
						width: 100%;
						height: 100%;
						// width: 258px;
						// height: 103.2px;
						// position: absolute;
						// left: 50%;
						// transform: translateX(-50%);
						// box-shadow: 0 4px 20px rgba(23, 25, 32, 0.04);
					}
				}


			}
		}

		.Headline2 {
			margin-bottom: 40px;

			.Headline2-t2 {
				font-size: 13px;
				color: #7f7f7f;
				padding-top: 5px;
			}

			.Headline2-t {
				font-size: 29px;
				font-weight: bold;
			}
		}
	}

	.dasj {
		width: 100%;
		height: 552px;
		position: relative;
		background: #fff url('../assets/home.png') top no-repeat;
		background-size: 100% 320px;

		.dasj-body {

			width: 1200px;
			margin: 0 auto;

			.dasj-body-bottom {
				.title {
					font-size: 28px;
					line-height: 36px;
					font-weight: 600;
					margin: 0 0 16px;
				}

				.title2 {
					display: -webkit-box;
					overflow: hidden;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 3;
					text-overflow: ellipsis;


					font-weight: 400;
					font-size: 14px;
					line-height: 30px;
					text-align: justify;
					color: #5a6274;
				}

				.button {
					cursor: pointer;
					text-align: center;
					color: white;
					border-radius: 0;
					width: 112px;
					height: 40px;
					line-height: 40px;
					background-color: #cb000f !important;
					font-style: normal;
					font-weight: 600;
					font-size: 12px;
					position: absolute;
					bottom: 40px;
					left: 40px;
				}

				padding: 40px;
				position: relative;
				margin-top: 40px;
				background-color: white;
				width: 100%;
				height: 280px;

				border-radius: 4px;
			}

			.dasj-body-top {
				padding: 40px 40px 0px;
				display: flex;
				justify-content: space-between;
				align-items: center;

				.dasj-body-oney:not(:last-child)::before {
					content: '';
					width: 1px;
					height: 30px;
					position: absolute;
					right: 0;
					top: 50%;
					transform: translateY(-50%);
					background-color: rgba(255, 255, 255, 0.34);
				}

				.dasj-body-oney {
					text-align: center;
					width: 20%;
					position: relative;

					.title {
						color: #fff;
					}

					.number {
						font-weight: 700;
						font-family: DIN Alternate;
						font-style: normal;
						color: white;
						font-size: 36px;
						line-height: 44px;
					}
				}
			}


		}

	}

	.hydt-bg {
		height: 660px;
		background: #171920 url(http://www.daas-auto.com/img/home/news_bg.png) top no-repeat;
		background-size: cover;

		.hydt {
			.hydt-center {
				.hydt-center-car:hover {
					cursor: pointer;

					.car-body {
						.car-about {
							.car-about-top {
								color: #118af3;
							}
						}
					}

				}

				.hydt-center-car {
					border-radius: 6px;
					width: 49.5%;
					height: 220px;
					background-color: white;
					margin-bottom: 1%;
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 0 24px;

					.car-body {
						.car-about {
							width: 360px;

							// position: absolute;
							// top: 50%;
							// left: 50%;
							// transform: translate(-50%,-50%);
							.car-about-top {
								font-size: 20px;
								font-weight: bold;
								display: -webkit-box;
								overflow: hidden;
								-webkit-box-orient: vertical;
								-webkit-line-clamp: 2;
								text-overflow: ellipsis;
								margin-bottom: 20px;
							}

							.car-about-bottom {
								font-size: 14px;
								line-height: 20px;
								text-align: justify;
								color: #5a6274;
								overflow: hidden;
								display: -webkit-box;
								-webkit-box-orient: vertical;
								-webkit-line-clamp: 5;
								text-overflow: ellipsis;
							}
						}

						position: relative;
						padding: 2px 12px;
						width: 360px;
						height: 180px;

					}

					.car-img {
						overflow: hidden;
						width: 240px;
						height: 180px;

						img {
							width: 100%;
							height: 100%
						}
					}
				}

				display: flex;
				justify-content: space-between;
				align-items: center;
				// flex-wrap: wrap;
			}

			width: 1200px;
			margin: 0 auto;

			.Headline {
				margin: 0px 0 35px;

				.Headline-t {
					font-size: 29px;
					font-weight: bold;
				}

				.Headline-t2 {
					font-size: 13px;
					color: #7f7f7f;
					padding-top: 5px;
				}
			}
		}
	}

	.hybg-car:hover {
		cursor: pointer;
	}

	.hybg-car {
		display: flex;
		flex-flow: wrap;

		.car:nth-child(4n) {
			margin-right: 0;
		}

		.car {
			box-shadow: 0 0 30px rgba(23, 25, 32, 0.08);

			.car-body {

				.car-about {
					.car-about-bottom {
						display: flex;
						justify-content: space-between;
						color: #999
					}

					.car-about-top {

						font-weight: 600;
						font-size: 15px;
						line-height: 18px;
						margin-bottom: 10px;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
					}

					position: absolute;
					left:50%;
					top:50%;
					transform: translate(-50%, -50%);
					width: 84%;
				}

				width: 100%;
				height:82px;
				position: relative;

			}

			.car-img {
				overflow: hidden;
				width: 100%;
				height: 218px;

				img {
					width: 100%;
					height: 100%
				}
			}

			margin-right:2%;
			width: 23%;
			height: 300px;
			margin-bottom: 20px;
			border-radius: 6px;
			background-color: white;
			overflow: hidden;
		}
	}

	.hybgw {
		padding-top: 50px;
		width: 100%;
		height: 877px;
		// background: #171920 url(http://www.daas-auto.com/img/home/hot_report_bg.png) 50% no-repeat;
		background-size: cover;

		.hybg {
			margin: 0 auto;
			width: 1200px;

			.Headline {
				margin: 0 0 35px;

				.Headline-t {
					font-size: 29px;
					font-weight: bold;
					color: white;
				}

				.Headline-t2 {
					font-size: 13px;
					color: #7f7f7f;
					padding-top: 5px;
					color: white;
				}
			}
		}
	}

	.ljbutton {
		width: 180px;
		height: 50px;
		line-height: 50px;
		color: white;
		font-size: 14px;
		font-weight: bold;
		background: #3e60ee;
		border-color: #3e60ee;
		border-radius: 4px !important;

	}

	.three {
		text-align: center;
		// width: 380px; 

		.body {
			height: 89px;
			background: url(http://www.daas-auto.com/img/home/rank.png) no-repeat;
			background-size: 100% 100%;
			position: relative;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			.body-t {
				font-weight: 600;
				font-size: 18px;
				line-height: 24px;
				text-align: center;
			}

			.body-t2 {
				margin-top: 8px;
				text-align: center;
			}
		}
	}

	.th {

		font-size: 12px;
		font-weight: 400;
		border-bottom: 0px solid red
	}

	.td2 {
		text-align: center;
		border-bottom: 0px solid red;
		text-align: center;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.td {

		text-align: center;
		border-bottom: 0px solid red;
	}

	.dongtai {
		width: 100%;
		background-color: white;

		.dongtai-head {
			width: 1200px;
			height: 300px;
			display: flex;
			margin: 0 auto;


			.dongtai-head-right2 {
				border: 1px solid #ecedf2;
				border-radius: 4px;
				padding: 0 20px;
				height: 304px;
				width: 49.5%;
				margin-left: 1%;
			}

			.dongtai-head-left2 {
				width: 49.5%;
				border: 1px solid #ecedf2;
				border-radius: 4px;
				padding: 0 20px;
				height: 304px;
			}

			.dongtai-head-left {
				width: 30%;
				min-width: 366px;

				.top {
					img {
						width: 132px;
						height: 78px;
					}

					;
					width: 100%;
					min-width: 366px;
					height: 142px;
					background: #fff url(http://www.daas-auto.com/img/home/industry_card_bg.png) 50% no-repeat;
					background-size: cover;
					border: 1px solid #ecedf2;
					border-radius: 4px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 0 20px;

					.title1 {
						font-size: 16px;
						font-weight: bold;
					}

					.title2 {
						font-size: 30px;
						font-weight: bold;
						margin: 8px 0;

						span {
							font-size: 14px;
							padding-left: 6px;
						}
					}

					.title3 {
						font-size: 14px;
						white-space: nowrap;

						span {
							span {
								color: #65cc97;
								padding: 0 6px;
							}
						}
					}
				}
			}

			.dongtai-head-right {
				border: 1px solid #ecedf2;
				border-radius: 4px;
				padding: 0 20px;
				height: 304px;
				width: 69%;
				margin-left: 1%;
			}
		}

		.dongtai-1 {

			width: 1200px;
			margin: 20px auto;
			position: relative;
		}
	}

	.Headline {
		margin: 50px 0 35px;

		.Headline-t {
			font-size: 29px;
			font-weight: bold;
		}

		.Headline-t2 {
			font-size: 13px;
			color: #7f7f7f;
			padding-top: 5px;
		}
	}

	.shuju {

		width: 1200px;
		margin: 20px auto;
		position: relative;

		.shuju-center {
			.last {
				background-color: #FFF;
				width: 32.8%;
				margin: 0 0 12px;
				height: 180px;

				.last-top {
					width: 100%;
					height: 50%;
					background: url(http://www.daas-auto.com/img/home/partner_bg.png) 100% no-repeat, linear-gradient(#e3e8fd 20%, #fff);
					background-size: 50% 100%;
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 21px 24px;
					transition: .3s;

					.title {
						font-size: 16px;
						font-weight: bold;
					}

					.title2 {
						font-size: 12px;
						padding-top: 3px;
					}

					.button {
						background-color: #3e60ee;
						color: white;
						padding: 6px 9px;
						font-weight: bold;
					}
				}

				.last-bottom {
					background: url(http://www.daas-auto.com/img/home/provider_bg.png) 100% no-repeat, linear-gradient(#fff5e0 20%, #fff);
					background-size: 50% 100%;
					height: 50%;
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 21px 24px;
					transition: .3s;

					.title {
						font-size: 19px;
						font-weight: bold;
					}

					.title2 {
						font-size: 12px;
						padding-top: 3px;
					}

					.button {
						background-color: #cb000f;
						color: white;
						padding: 6px 9px;
						font-weight: bold;
					}
				}
			}

			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			.shuju-center-each {
				.shuju-center-button {
					position: absolute;
					top: 20px;
					right: 20px;
					background-color: #3e60ee;
					font-size: 14px;
					color: white;
					font-weight: bold;
					padding: 6px 9px;
				}

				background-color: #FFF;
				width: 32.8%;
				margin:0 0 12px;
				height: 180px;
				padding: 20px;
				position: relative;

				.a {
					font-size: 19px;
					font-weight: bold;
				}

				.b {
					font-size: 13px;
					color: #626262;
					padding-top: 3px;
				}

				.c {
					font-size: 13px;
					color: #626262;
					padding-top: 13px;
				}

				.d-both {
					position: absolute;
					bottom: 20px;
					left: 20px;
					display: flex;

					.d {
						background-color: #f0f3ff;
						color: #3e60ee;
						font-size: 12px;
						padding: 3px 6px;
					}

					.e {
						background-color: #ddfbed;
						color: #13b36a;
						font-size: 12px;
						padding: 3px 6px;
						margin-left: 5px;
					}
				}
			}
		}

	}

	.showData {
		width: 300px;
		height: 80px;
		background-color: white;
		position: relative;

		.center {
			position: absolute;
			top: 50%;
			transform: translatey(-50%);

			.center-title {
				font-size: 13px;
				text-align: left;

			}

			.center-title2 {
				padding-top: 6px;
				font-size: 12px;
				color: #999;
			}
		}


	}

	.home-body {
		.zwf {
			position: sticky;
			top: 0;
			width: 100%;
			height: 90px;
			background-color: #222328;
		}

		.home-body-head {
			.left-show {
				display: flex;
				flex-direction: column;
				flex-wrap: wrap;
				z-index: 23;
				color: #343434;
				height: 460px;
				position: absolute;
				top: 0;
				left: 200px;
				background-color: white;
			}

			.right {
				position: absolute;
				right: 0;
				top: 0;
				width: 100%;
				// min-width: 1000px;
				z-index: 22;
				height: auto;
			}

			width: 100%;
			// margin: 20px auto 40px;
			height: auto;
			position: relative;
			background-color: white;

			.left:hover {
				color: blue;
				cursor: pointer;
				box-shadow: -8px 0px 8px 8px rgba(0, 0, 0, 0.02)
			}

			.left {
				height: 80px;
				width: 100%;

				.left-title {
					height: 80px;
					position: relative;
					width: 200px;

					.left-title-view {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						padding: 10px 14px;
						width: 200px;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;

						.title {
							font-size: 16px;
						}

						.Ftitle {
							padding-top: 6px;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
							width: 80%;

							span {
								margin-right: 6px;
								font-size: 12px;
								color: #999;
							}
						}
					}
				}
			}
		}
	}
</style>
