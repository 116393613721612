<template>
	<div class="headd" :class="ishow ? 'bg-bb' :'bg-bb2'">
		<div style="width: 100%;	height: 90px;position: relative;display: flex;justify-content: space-between;">
			<div class="" style=" width: 220px;height: 90px;position: relative; ">
				<!-- <div style="background-color: #cb000f;right:-30px;bottom: 0;  clip-path: polygon(0 0, 100% 0, 0 100%, 0 0);position: absolute;width: 30px;height: 90px;"></div> -->
				<img :src="url + bottom.topLogo" v-if="url + bottom.logo"
					style="width: 220px;height: 90px;position: absolute;left: 50%;top: 50%;transform: translate(-50%,-50% );"></img>
			</div> 
			<!-- filter: brightness(0) invert(1); -->
			<div class=" " style="min-width: 1000px;display: flex;align-items: center;" id="tabbarIndex">
				<div class="action" @click="NavChange(item,index)" style="position:relative"
					v-for="(item,index) in dataPage">
					<div :class=" indexa ==  item.nature ? 'text-yellow':'text-suu'">{{item.title}} 
					</div>

					<!-- <img v-if="item.is_hot == 1 " style="width: 40px;
					    height: 20px;
					    position: absolute;
					    right: -10px;
					    top: -19px;" src="https://www.daas-auto.com//img/common/hot.png">
					</img> -->
				</div>
			</div>
			<div @mouseenter="onMouseOver()" @mouseleave="onMouseOut()"
			:class="islogin.userToken ? 'backhead2' : 'backhead' "
			 style="min-width: 136px;height: 90px;right: 0;bottom: 0;line-height: 90px;cursor: pointer;text-align: center;
			     ">
				 <div  v-if="islogin.userToken" style="white-space: nowrap;color: white;
			     overflow: hidden;
			     text-overflow: ellipsis;padding: 0 20px;font-weight: bold;">
					 {{islogin.nickname ? islogin.nickname:'暂无名称'}}
					 <span v-show="!tuichuShow"><a-icon type="caret-up"  style="padding-left: 8px;font-weight: bold;"/></span>
					 <span v-show="tuichuShow"><a-icon type="caret-down"  style="padding-left: 8px;font-weight: bold;"/></span>
				 </div>
				 
				<span @click="login" style=" padding: 2px ;color: white;" v-else>登录/注册</span>
				 
				 <div  v-show="tuichuShow" style="background-color: white;">
					 <div @click="set" style="height: 60px;width:136px;text-align: center;line-height: 60px;cursor: pointer;
					  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.02);">
					  	我的账户 
					  </div> 
					 <div @click="tuichu" style="height: 60px;width: 136px;text-align: center;line-height: 60px;cursor: pointer;
					 box-shadow: 0 2px 8px rgba(0, 0, 0, 0.02); "  >
					 	退出登录 
					 </div>
				 </div>
				
			</div>
			
			
		</div>
		<loginButton ref="loginButton"></loginButton>
	</div>
</template>

<script>
	import { mapState } from 'vuex'; 
	import loginButton from '@/components/loginButton.vue';
	
	export default {
		name: 'hhd', 
		props: ['url','dataPage','bottom','indexa'], 
		components: {
			loginButton
		},
		data() {
			return {
				ishow:false,
				item:{},
				tuichuShow:false,
				subNewsData: {}, 
				PageCur: 'home',
				  
			}
		},
		watch: {},
		computed: mapState({
			islogin:state=>state.islogin
		}), 
		created() {
		   //添加滚动监听事件
		   //在窗口滚动时调用监听窗口滚动方法
		   window.addEventListener('scroll', this.windowScrollListener);
		},
		mounted() { 
			 
		}, 
		methods: { 
			windowScrollListener() {
			  //获取操作元素最顶端到页面顶端的垂直距离
			  var scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
			  if (scrollTop >= 90) {
			     this.ishow = true
			  }
			  if (scrollTop < 90) {
			    this.ishow = false
			  }
			},
		 
			set(){
				this.$router.push({
					name: 'user'
				})
			},
			onMouseOut() {
				this.tuichuShow = false
			},
			onMouseOver() {
				if(this.islogin.userToken){
					this.tuichuShow = true
				}
				
			},
			tuichu(){
				this.$store.commit('UPDATA_out','')
				this.tuichuShow = false
			},
			login() {
				 
				this.$refs.loginButton.lognShow()
			},
			 
			show() {},
			hide() {
				this.$refs.loginButton.lognHide()
			},
			NavChange(item, ind) {   
				if(this.$store.state.index == ind){ 
					return
				}  
				
				this.PageCur = item.nature;
				this.$router.push({
					name: item.nature
				})
			}
		}

	}
</script>

<style scoped lang="scss">
	.backhead2{
		color: #1d1d1d !important;
	}
	.backhead{
		// background-color: #cb000f;
	}
	.text-suu{
		height: 90px;
		line-height: 90px;
		padding: 0 37px; 
		color: white;
		font-weight: bold;
	}
	.text-yellow {
		height: 90px;
		line-height: 90px;
		padding: 0 37px;
		background-color: #cb000f;
		font-weight: bold;
		color: white;
	}
	.bg-bb2{
		 border-bottom: 1px solid #7f7f7f;
	}
.bg-bb{
	background-color: #222328;
}
	.headd {
		box-sizing: border-box;
		min-width: 1300px;
		width: 100%;
		height: 90px;
		position: fixed;
		top: 0;
		display: flex;

		box-shadow: #cb000f;
		box-shadow: 0 15px 10px -15px rgba(0, 0, 0, 0.06);
		z-index: 999;
	}

	.action {
		text-align: center;
		font-size: 16px;
		
		 
	}

	.action :hover {
		cursor: pointer; 
			height: 90px;
			line-height: 90px;
			padding: 0 37px;
			background-color: #cb000f;
			font-weight: bold;
			color: white;
		
	}

	page {
		background-color: #f4f5fa !important;
	}
</style>
