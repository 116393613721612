import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../views/home.vue'
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
	 
	if (onResolve || onReject)
		return originalPush.call(this, location, onResolve, onReject);
		
	return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'home',
		component: home
	},
	{
		path: '/report',
		name: 'report',
		component: () => import('../views/report.vue')
	},
	{
		path: '/list',
		name: 'list',
		component: () => import('../views/list.vue')
	},
	{
		path: '/shop',
		name: 'shop',
		component: () => import('../views/shop.vue'),
		children: [{
				path: '/country_retail',
				name: 'country_retail',
				component: () => import('../views/shopRouter/country_retail.vue'),
			},
			{
				path: '/merchant_sales',
				name: 'merchant_sales',
				component: () => import('../views/shopRouter/merchant_sales.vue'),
			},
			{
				path: '/country_inventory',
				name: 'country_inventory',
				component: () => import('../views/shopRouter/country_inventory.vue'),
			},
			{
				path: '/country_wholesale',
				name: 'country_wholesale',
				component: () => import('../views/shopRouter/country_wholesale.vue'),
			},
			{
				path: '/country_output',
				name: 'country_output',
				component: () => import('../views/shopRouter/country_output.vue'),
			},
			{
				path: '/merchant_inventory',
				name: 'merchant_inventory',
				component: () => import('../views/shopRouter/merchant_inventory.vue'),
			},
			{
				path: '/ne_retail_sales',
				name: 'ne_retail_sales',
				component: () => import('../views/shopRouter/ne_retail_sales.vue'),
			},
			{
				path: '/ne_retail_inventory',
				name: 'ne_retail_inventory',
				component: () => import('../views/shopRouter/ne_retail_inventory.vue'),
			},
		]
	}, {
		path: '/info',
		name: 'info',

		component: () => import('../views/info.vue')
	}, {
		path: '/about',
		name: 'about',

		component: () => import('../views/about.vue')
	}, {
		path: '/reportInfor',
		name: 'reportInfor',

		component: () => import('../views/reportInfor.vue')
	},
	{
		path: '/supply',
		name: 'supply',
		component: () => import('../views/supply.vue')
	}, {
		path: '/user',
		name: 'user',
		component: () => import('../views/user.vue')
	}, {
		path: '/report_gm',
		name: 'report_gm',
		component: () => import('../views/report_gm.vue')
	}, {
		path: '/buyLog',
		name: 'buyLog',
		component: () => import('../views/buyLog.vue')
	},
	
{
		path: '/buyLog_info',
		name: 'buyLog_info',
		component: () => import('../views/buyLog_info.vue')
	},{
		path: '/buyLog_info2',
		name: 'buyLog_info2',
		
		component: () => import('../views/buyLog_info2.vue')
	},

]


const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})




export default router
