import toast from "@/components/toast"
export default (Vue) => {
    let toastComp = Vue.extend(toast);
    function showToast(msg ,imgUrl = '', duration = 3e3){
        let toastDom = new toastComp({
            data(){
                return{
                    show: true,
                    msg: typeof msg === 'object'? msg.msg: msg,
                    imgUrl: typeof msg === 'object'? msg.imgUrl: imgUrl,
                }
            }
        }).$mount()
        document.body.appendChild(toastDom.$el);
        setTimeout(() => {
            toastDom.show = false
        }, duration)
    }
    Vue.prototype.$toast = showToast;
}
 