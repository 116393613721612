import { JSEncrypt } from './jsencrypt'
 
// 加密公钥
const key = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAz5yQYGz9GwWvxOqvJIqA
1M4XrEq0SkE383CDUJdpBzybUs2hzXKWwjQtHLhSj7j4OqEjADapmp23WdStsEkr
VWqvecAp6BqSWdSfJNBIvVTMyQQ+/3lw3S1r+CE7z5REo0IzPGkBkj009xwtLJic
k+OetxGCPumXppR+HYDU/jqIFQKuZrP6Hod9zogMQ2qdIq26Dwp7eVUwCH2T7NvD
lb4MO3CgrgYVRSrPsgi0OqE8noRFnzP0KL+KhfnkSmdScUfkD9WZRMZ03MFeqUO0
ycJ1TvWPAacTosTx8mBuoIpvI8q744IEHW7y8vAMbvczGpSDBOWyzu6Gna78qkTc
pQIDAQAB
-----END PUBLIC KEY-----
`
 
// 加密
export function rsaEncrypt (msg) {
  const jsencrypt = new JSEncrypt()
  jsencrypt.setPublicKey(key)
  const encryptMsg = jsencrypt.encrypt(msg)
  return encryptMsg
}
 
// 解密私钥
const privateKey = ``
 
// 解密
export function rsaDecrypt (msg) {
  const decrypt = new JSEncrypt()
  decrypt.setPrivateKey(privateKey)
  const decryptMsg = decrypt.decrypt(msg)
  return decryptMsg
}
 