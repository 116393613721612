<template>
	<div id="app" class="app" style="min-width: 1600px;">
		<Header :url="url" :dataPage="dataPage"   :bottom="bottom" :indexa="$store.state.index">
		</Header>
		<div id="components-back-top-demo-custom">
			<a-back-top>
				<div class="ant-back-top-inner">
					<a-icon type="arrow-up" />
				</div>
		 </a-back-top>

		</div>
		<router-view />
		<foot :url="url" :dataa="bottom" :footMenu="footMenu" v-show="$store.state.index !='shop' && $store.state.index != 'supply'"
			   ></foot> 
	</div>
</template>
<script>
	import {
		headTitle
	} from './api/index.js' //引入页面
	import Header from './components/hhd.vue'
	import foot from './components/foot.vue'
	
	export default {
		name: 'HomeView',
		components: {
			Header,
			foot
		},
		data() {
			return {
				namee: '',
				content: '',
				footMenu: [

				],
				dataPage: [{
					nature: 'home',
					title: '首页'
				}],
				url: '',
				bottom: {}
			}
		},
		metaInfo() {
			return {
				meta: [{
						name: "keywords",
						content: this.namee
					},
					{
						name: "description",
						content: this.contentt
					}
				]
			}
		},
		mounted() {
			headTitle({}).then(res => {
				 
				if (res.status == 1) { 
					this.url = res.data.url
					this.dataPage = [...this.dataPage, ...res.data.menu]
					this.bottom = res.data.website
					this.footMenu = res.data.footMenu
					sessionStorage.setItem('img', JSON.stringify(res.data.url + res.data.website.logo))
					document.title = res.data.website.title
					this.namee = res.data.website.keywords
					this.contentt = res.data.website.description 
					this.$store.commit('imgurl_fun',this.url+this.bottom.logo)
				}
			})
		},
		methods: {
 
			changeMsgFn(value) { },
		}
	}
</script>


<style lang="scss">
	* {
		margin: 0;
		padding: 0
	}

	// @import "comon/main.css";
	@import "comon/icon.css";
	@import "comon/animate.css";

	// @import "comon/free.css";
	#components-back-top-demo-custom .ant-back-top {
		bottom: 100px;
		z-index:24;
	}

	#components-back-top-demo-custom .ant-back-top-inner {
		height: 50px;
		width: 50px;
		line-height: 50px;
		border-radius: 4px;
		background-color: #cb000f;
		color: #fff;
		text-align: center;
		font-size: 28px;
	}

	#app {
		
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color: #2c3e50;
	}
	

	nav {
		padding: 30px;

		a {
			font-weight: bold;
			color: #2c3e50;

			&.router-link-exact-active {
				color: #42b983;
			}
		}
	}
</style>
