<template>
	<div  v-show="tanchuang" style="position: fixed;z-index: 999999;left: 0;right: 0;top: 0;bottom: 0;">
		<div @click.stop="tanchuang=false" style="width:100%;height:100%;background-color: rgba(0,0,0,0.7);position: relative;">
			<div @click.stop=""   style="position: absolute;width: 300px;
			height: 400px;background-color: white;
			top:50%;left: 50%;transform: translate(-50%,-50%);border-radius: 8px;
			">
				<div style="width: 100%;text-align: center;font-size: 20px;font-weight: bold;padding-top: 20px;">
					 {{website.contacttips}}
				</div>
				<div style="width: 200px;margin: 20px auto 0;font-size: 18px;text-align: center;">
					<!-- {{website.qrcodetips}} -->
					客服电话
				</div>
				<div style="width: 200px;margin: 0 auto;font-size: 18px;text-align: center;">
					{{website.tel}}
				</div>
				<div style="width: 200px;margin: 13px auto 0;font-size: 18px;text-align: center;">
					<!-- {{website.qrcodetips}} -->
					客服微信
				</div>
				<div style="width: 200px;height: 200px;background-color: red;position: absolute;bottom: 0;left: 50px;bottom: 30px;">
					<img :src="url+website.wxservice" style="width: 100%;height:  100%;"  alt="">
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { headTitle } from '../api/index.js' //引入页面
	export default {
		components: {  
		}, 
		data() {
			return {
				tanchuang:false,
				 website:{},
url:'',
			}
		},
		watch: { },
		 mounted() {
		 	headTitle({}).then(res => {
		 		if (res.status == 1) {
		 			 
						this.website = res.data.website
						this.url = res.data.url
		 		}
		 	}) 
		 }, 
		methods: {
			    show(){
					this.tanchuang = true
				}
			 
		},
	}
</script>

<style>
</style>