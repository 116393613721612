import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import md5 from '@/utils/md5';
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import moment from 'moment';
import locale from 'ant-design-vue/lib/locale-provider/zh_CN';
import 'moment/locale/zh-cn';
import loginButton from '@/components/loginButton.vue';
import toast from "../public/toast";
  
import '@/assets/gless.less'
import AOS from "aos";
import 'aos/dist/aos.css'
Vue.use(AOS)

import Meta from "vue-meta";
Vue.use(Meta);

import toastPop from "@/components/toastPop.vue";
Vue.component('toastPop', toastPop)

Vue.use(toast);
Vue.component('loginButton', loginButton)

import SlideVerify from 'vue-monoplasty-slide-verify';

Vue.use(SlideVerify);
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'

Vue.use(VXETable)

moment.locale('zh-cn');

Vue.use(Antd)
/**判断是否是手机号**/
Vue.prototype.$isPhoneNumber=(tel)=>{
	var reg =/^0?1[3|4|5|6|7|8][0-9]\d{8}$/;
	return reg.test(tel);
}

  /**深拷贝**/
Vue.prototype.$deepClone = (obj) => {
	var _obj = JSON.stringify(obj),
		objClone = JSON.parse(_obj);
	return objClone;
}
Vue.config.productionTip = false
new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
