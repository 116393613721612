<template>
	<div class="modal-wrap" v-show="logn" @click.stop="lognHide">
		<div v-show='zcishow' @click.stop="ldd" style="width: 440px;background-color: white;
			
			position: absolute;
			left: 50%;
			top: 50%;  
			transform: translate(-50%, -50%);" >
			<div class="headimg">
				<img :src="$store.state.imgurl">
			</div>
			<div style="width: 100%;padding: 30px 20px;overflow-y: auto;" class="overflow-yy">
				<div class="swbody3">
					<div>姓名：</div>
					<a-input placeholder="姓名" v-model="zcname" />
				</div>
				<div class="swbody3">
					<div>公司名称：</div>
					<a-input placeholder="公司名称" v-model="zcmc" />
				</div>
				<div class="swbody3">
					<div>部门：</div>
					<a-input placeholder="请填写您的部门" v-model="zcbm" />
				</div>
				<div class="swbody3">
					<div>职位：</div>
					<a-input placeholder="请填写您的职位" v-model="zczw" />
				</div>
				<div class="swbody3">
					<div>所在城市：</div>
					<a-input placeholder="公司所在城市" v-model="zccs" />
				</div>
				
				<div class="swbody3">
					<div>公司地址：</div>
					<a-input placeholder="公司地址" v-model="zcdz" />
				</div>
				<div class="swbody3">
					<div>手机号码：</div>
					<a-input placeholder="手机号码" v-model="zcphone" />
				</div>
				<div class="swbody3">
					<div>验证码：</div>
					<a-input placeholder="短信验证码" v-model="zcdx" />
					<div class="butt" style="height: 32px;line-height: 32px;" @click="getYZM5" v-show="djs"> 获取验证码 </div>
					<div class="butt" style="background: #e5e5e5;height: 32px;line-height: 32px;" v-show="!djs">
						{{count}}
					</div>
				</div>
				<div class="swbody3">
					<div>公司邮箱：</div>
					<a-input aria-autocomplete="off" placeholder="公司邮箱" v-model="zcyx" />
				</div>
				<div class="swbody3">
					<div>密码：</div>
					<a-input-password 
					 style="  flex: 1; 	border-color: #cb000f; border-right-width: 1px !important;" placeholder="登录密码" v-model="zcpasss" />
				</div>
				<div style="font-size: 12px;  cursor: pointer;text-align: center;float: right;color: #999999;" @click="subzcgback">已有账户,返回登录</div>
				<div style=" width: 100%;text-align: center;height: 40px;line-height: 40px;margin: 40px 0 10px;">
					
					<div style="font-size: 20px;cursor: pointer;width: 100%;background-color: #cb000f;color: white;" @click="subzc">确定</div>
					
					
					<div>
					</div>
				</div>
				
				<div class="agreement" style="display: flex;margin-top: 4px;">
					<div style="width: 70px;">
						<a-checkbox @change="onChange2" :checked="checkauto2">
							同意
						</a-checkbox>
					</div>
					<div>
						<span class="agreement-info" @click="getAgreement(item.id)"
							v-for="(item,index) in xy" :key="index">《{{item.title}}》 </span>
						<!-- <span class="agreement-info" @tap="getAgreement('2')">《达示平台用户隐私协议》</span> -->
					</div>
				</div>
				
				
				
			</div>
		</div>
		<div v-show='!zcishow'>
			<div class="modal-wrap-body" style="background-color: white;height: 310px;" v-show="isPoass">
				<div class="headimg">
					<img :src="$store.state.imgurl">
				</div>
				<div style="padding: 20px;">zz
					<div class="swbody">
						<a-input-password placeholder="请输入密码" v-model="firstPowss" />
					</div>
					<div class="swbody">
						<a-input-password placeholder="请再次输入密码" v-model="endPowss" />
					</div>
				</div>
				<div style="width:100%;height:40px;position: relative;">
					<div style="width:60px;height:40px;position: absolute;top:50%;left: 50%;transform: translate(-50%,-50%);text-align: center;line-height: 40px;background-color: #3e60ee;color: white;"
						@click="subPowss">
						确定
					</div>
				</div>
			</div>

			<div class="modal-wrap-body" @click.stop="no" v-show="!isPoass">
				<div class="headimg">
					<img :src="$store.state.imgurl">
				</div>

				<div class="login-main">
					<div v-show="!passwordChange">
						<div>
							<div class="scroll-div">
								<div class="body-div" v-for="(item,index) in scrolldivList" :key="index"
									@click.stop="changeSwiper(index)">
									<div class=" " :class="[currentTab==index ? 'currentTab' : '']">
										{{item}}
									</div>
								</div>
							</div>
							<div>
								<div v-show="currentTab == 0" class="currentTab1">
									<div class="swbody">
										<!-- <span class="body">
											<img src="https://www.daas-auto.com/_nuxt/img/phone.bcc3f48.svg">
										</span> -->
										<!-- <input placeholder="手机号码" type="text" class="ant-input"> -->
										<a-input v-model="userName" placeholder="请输入手机号">
											<a-icon slot="prefix" type="user" />
										</a-input>
									</div>
									<div class="swbody">
										<a-input v-model="userYzm" placeholder="请输入验证码">
											<a-icon slot="prefix" type="user" />
										</a-input>
										<div class="butt" @click="getYZM" v-show="djs"> 获取验证码 </div>
										<div class="butt" style="background: #e5e5e5;" v-show="!djs">
											{{count}}
										</div>

									</div>
								</div>
								<div v-show="currentTab != 0" class="currentTab1">
									<div class="swbody">

										<a-input placeholder="请输入手机号" v-model="userName">
											<a-icon slot="prefix" type="user" />
										</a-input>
									</div>
									<div class="swbody">
										<a-input-password placeholder="请输入密码" v-model="userPowss" />
									</div>
								</div>
							</div>
							<div class="agreement">
								<div style="display: flex;justify-content: space-between;width: 100%;">
									<a-checkbox @change="onChange" :checked="checkauto">
										自动登录
									</a-checkbox>
									<div><span style="margin-right: 20px;cursor: pointer" @click="sugZC">注册</span> <span
											@click="changPassword(1)" style="cursor: pointer">忘记密码</span>
									</div>
								</div>
							</div>
							<div
								style="width: 100%;height: 60px;display: flex;justify-content: center;align-items: center; ">
								<div v-if="currentTab  == 0" class="login-button" @click="dxLogn"><span>登 录</span></div>
								<div v-else class="login-button" @click="passLogn"><span>登 录</span></div>
							</div>
							<div class="agreement" style="display: flex;margin-top: 4px;">
								<div style="width: 70px;">
									<a-checkbox @change="onChange2" :checked="checkauto2">
										同意
									</a-checkbox>
								</div>
								<div>
									<span class="agreement-info" @click="getAgreement(item.id)"
										v-for="(item,index) in xy" :key="index">《{{item.title}}》 </span>
									<!-- <span class="agreement-info" @tap="getAgreement('2')">《达示平台用户隐私协议》</span> -->
								</div>
							</div>

						</div>
					</div>

					<div style="padding-top: 20px;position: relative;" v-show="passwordChange">
						<div style=" font-weight: bold;color: #999;text-align: left;cursor: pointer;margin-bottom: 15px;"
							@click="passwordChange=false">
							<a-icon type="left" />返回登录
						</div>

						<div class="swbody">
							<a-input v-model="userName" placeholder="请输入手机号">
								<a-icon slot="prefix" type="user" />
							</a-input>
						</div>
						<div class="swbody">
							<a-input v-model="SendYzm" placeholder="请输入验证码">
								<a-icon slot="prefix" type="user" />
							</a-input>
							<!-- <div class="butt" @click="getYZM2"> 获取验证码 </div> -->
							<div class="butt" @click="getYZM2" v-show="djs2"> 获取验证码 </div>
							<div class="butt" style="background: #e5e5e5;" v-show="!djs2">
								{{count2}}
							</div>
						</div>
						<div class="swbody">
							<a-input-password placeholder="请输入密码" v-model="firstPowss1" />
						</div>
						<div class="swbody">
							<a-input-password placeholder="请输入密码" v-model="endPowss2" />
						</div>
						<div style="width: 100%;text-align: center;">
							<span style="background-color: #cb000f;color: white;padding: 10px 20px;cursor: pointer;"
								@click="SendPass">确定</span>
						</div>

					</div>
				</div>
			</div>
		</div>
		<div class="yzm" v-show="isYZM3" @click.stop="isYZMno3">
			<div style="width: 100%;height: 100%;position: relative;">
				<div class="bodyy"
					style="position: absolute;left: 50%;top: 50%;transform: translate(-50%,-50%);background-color: white;z-index: 100022;position: relative;padding-top: 10px;">
					<div @click.stop="subMark23" style="right: 10px;top: 10px;position: absolute;">
						<a-icon type="close" />
					</div>

					<div style="width: 300px;height:60px;text-align: center;margin-top: 20px;">
						<img :src="baseimg3" @click="getYZM3" alt="" style="width: 100px;height: 60px; ">
					</div>
					<div
						style="position: absolute;bottom: 10px;display: flex;justify-content: space-around;align-items: center;padding:6px 20px;box-sizing: border-box;">
						<div>
							<a-input placeholder="请输入验证码" v-model="verity3" />
						</div>
						<div @click="getdx3"
							style="width: 80px;height: 30px;background-color: #cb000f;color: white;line-height: 30px;cursor: pointer;">
							确定
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="yzm" v-show="isYZM" @click.stop="isYZMno">
			<div style="width: 100%;height: 100%;position: relative;">
				<div class="bodyy"
					style="position: absolute;left: 50%;top: 50%;transform: translate(-50%,-50%);background-color: white;z-index: 100022;position: relative;padding-top: 10px;">
					<div @click.stop="subMark2" style="right: 10px;top: 10px;position: absolute;">
						<a-icon type="close" />

					</div>

					<div style="width: 300px;height:60px;text-align: center;margin-top: 20px;">
						<img :src="baseimg" @click="getYZM" alt="" style="width: 100px;height: 60px; ">
					</div>
					<div
						style="position: absolute;bottom: 10px;display: flex;justify-content: space-around;align-items: center;padding:6px 20px;box-sizing: border-box;">
						<div>
							<a-input placeholder="请输入验证码" v-model="verity" />
						</div>
						<div @click="getdx"
							style="width: 80px;height: 30px;background-color: #cb000f;color: white;line-height: 30px;cursor: pointer;">
							确定
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="yzm" v-show="SendIsPoass" @click.stop="isYZMno">
			<div style="width: 100%;height: 100%;position: relative;">
				<div class="bodyy"
					style="position: absolute;left: 50%;top: 50%;transform: translate(-50%,-50%);background-color: white;z-index: 100022;position: relative;padding-top: 10px;">
					<div @click.stop="subMark" style="right: 10px;top: 10px;position: absolute;">
						<a-icon type="close" />
					</div>

					<div style="width: 300px;height:60px;text-align: center;margin-top: 20px;">
						<img :src="baseimg2" @click="getYZM2" alt="" style="width: 100px;height: 60px; ">
					</div>
					<div
						style="position: absolute;bottom: 10px;display: flex;justify-content: space-around;align-items: center;padding: 20px;box-sizing: border-box;">
						<div>
							<a-input placeholder="请输入验证码" v-model="verity2" />
						</div>
						<div @click="getdxx"
							style="width: 80px;height: 30px;background-color: #cb000f;color: white;line-height: 30px;cursor: pointer;">
							确定
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="yzm" v-show="pop" @click.stop="popno">
			<div style="width: 100%;height: 100%;position: relative;">
				<div
					style="box-sizing: border-box;width: 700px;height: 620px;background-color: white;position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%);">
					<div style="width: 100%;height: 50px;border-bottom: 1px solid #cccccc;display: flex;align-items: center;justify-content: space-between;padding: 0 10px;    font-size: 22px;color: #171920;">
						<div style="padding-left: 20px;">{{cont}}</div>
						<div style="#cccccc" @click="pop=false">
							<a-icon type="close" />
						</div>
					</div>
					<div style="width: 100%;height: 540px;padding: 30px;overflow: auto;">
						<div v-html="ddhtml">

						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		aes_encrypt,
		aes_decrypt
	} from '@/utils/aes_endecrypt.js';
	import {
		Login_registere,
		Agreemen_lists,
		Agreement_content,
		Login_getback,
		Login_passlogin,
		Login_setpass,
		Login_notelogin,
		Sendmsg_imageyzm,
		Sendmsg_sendmsg
	} from '../api/index.js' //引入页面 
	import {
		mapState
	} from 'vuex';
	export default {
		name: "loginButton",
		computed: mapState({
			state_Sendkey: state => state.state_Sendkey,
			islogin: state => state.islogin,
		}),

		data() {
			return {
				cont:'',
				baseimg8: '',
				baseimg3: '',
				zcname: '',
				zcmc: '',
				zcbm: '',
				zczw: '',
				zccs: '',
				zcdz: '',
				zcphone: '',
				zcyz: '',
				zcdx: '',
				zcyx: '',
				zcpasss: '',
				authCodeKey3: '',
				zcishow: false,
				sugZCisshow: false,
				count2: 0,
				timer2: null,
				djs2: true,
				count: 0,
				timer: null,
				djs: true,
				ddhtml: '',
				pop: false,
				xy: [],
				firstPowss1: '',
				endPowss2: '',
				baseimg2: '', 
				verity2: '',
				authCodeKey2: '',
				SendIsPoass: false,
				SendYzm: '',
				checkauto: true,
				checkauto2: true,
				userPowss: '',
				firstPowss: "",
				endPowss: "",
				isPoass: false,
				userValue: '',
				isYZM: false,
				isYZM3: false,
				userName: '',
				userYzm: '',
				passwordChange: false,
				baseimg: '',
				verity: '',
				verity3: '',
				authCodeKey: '',
				userValue33:'',
				is_read: true, //是否阅读
				logn: false,
				currentTab: 1,
				scrolldivList: ["短信登录", "密码登录"],
			};
		},
		mounted() {
			Agreemen_lists({}).then(res => {
				if (res.status == 1) {
					this.xy = res.data
				}
			})
			var storage = window.localStorage;
			if (storage.getItem("userqc")) {
				this.userName = JSON.parse(storage.getItem("userqc")).phone
				this.userPowss = JSON.parse(storage.getItem("userqc")).pass

			}
		},
		methods: {
			subzcgback() {
this.zcishow = false 
			},
			subzc() {
				
				if (this.zcname=='') {
					this.$message.info('请填写姓名');
					return
				}
				if (this.zcmc=='') {
					this.$message.info('请填写公司名称');
					return
				}
				if (this.zcbm=='') {
					this.$message.info('请填写公司部门');
					return
				}
				if (this.zczw=='') {
					this.$message.info('请填写公司职位');
					return
				}
				
				if (this.zccs=='') {
					this.$message.info('请填写公司城市');
					return
				}
				if (this.zcdz=='') {
					this.$message.info('请填写公司地址');
					return
				}
				
				
				if (this.zcphone=='') {
					this.$message.info('请填写手机号');
					return
				}
				if (this.zcphone.length<11) {
					this.$message.info('请填写正确手机号');
					return
				}
				if (this.zcdx=='') {
					this.$message.info('请填写短信验证码');
					return
				}
				if (this.zcyx=='') {
					this.$message.info('请填写邮箱');
					return
				}
				function IsEmail(str) {							var reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;							return reg.test(str);						}
				if(!IsEmail(this.zcyx)){
					this.$message.info('请填写正确邮箱');
					return
				}
				if (this.zcpasss=='') {
					this.$message.info('请填写密码');
					return
				}
				let a = {
					phone: this.zcphone,
					verity: this.zcdx,
					authCodeKey: this.userValue33,
					user_pass: this.zcpasss,
					name: this.zcname,
					company: this.zcmc, // 公司名称：
					department: this.zcbm, //部门：
					position: this.zczw, // 职位：
					city: this.zccs, //城市：
					address: this.zcdz, // 公司地址：
					email: this.zcyx //邮箱：
				}
				Login_registere(a).then(res => {
					if(res.state = 1){
						this.$message.success(res.msg); 
						// let key = this.$deepClone(this.$store.state.state_Sendkey.key)
						// let iv = this.$deepClone(this.$store.state.state_Sendkey.iv)
						// let c = JSON.parse(aes_decrypt(res.data, key, iv)) 
						// this.$store.commit('UPDATA_login', c)  
						 this.zcphone =''
						 this.zcdx =''
						 this.userValue33 =''
						 this.zcpasss =''
						 this.zcname =''
						 this.zcmc =''
						 this.zcbm =''
						 this.zczw =''
						 this.zccs =''
						 this.zcdz =''
						this.zcyx =''
						this.logn = false
						this.zcishow = false 
						
						// setTimeout((
						// )=> {}, 500);
					}else{
						this.$message.info(res.msg);
					}
				})
			},
			ldd() {},
			sugZC() {
				 
				this.zcishow = true
			},
			getAgreement(aa) {
				let a = {
					id: aa
				}
				Agreement_content(a).then(res => {
					if (res.status == 1) {
						this.pop = true
						this.ddhtml = res.data.content
						this.cont = res.data.title 
					}

				})

			},
			subMark23() {
				this.isYZM3 = false
			},
			subMark2() {
				this.isYZM = false
			},
			subMark() {

				this.SendIsPoass = false
			},
			SendPass() {
				if (this.endPowss2 == this.firstPowss1) {
					let a = {
						phone: this.userName,
						type: 'getback',
						verity: Number(this.SendYzm),
						authCodeKey: this.authCodeKey2,
						password: this.endPowss2
					}

					Login_getback(a).then(res => {
						if (res.status == 1) {
							this.$message.success(res.msg);
							setTimeout(() => {
								this.passwordChange = false
							}, 500);

						}
					})

				}

			},
			getdxx() {
				let a = {
					phone: this.userName,
					verity: this.verity2,
					authCodeKey: this.authCodeKey2,
					type: 'getback' //类型：登录：login；找回密码
				}
				Sendmsg_sendmsg(a).then(res => {
					if (res.status == 1) {
						this.SendIsPoass = false
						this.authCodeKey2 = res.data.name

						this.djs2 = false
						this.count2 = 60;
						this.djs2 = false;
						this.timer2 = setInterval(() => {
							if (this.count2 > 0 && this.count2 <= 60) {
								this.count2--;
							} else {
								this.djs2 = true;
								clearInterval(this.timer2);
								this.timer2 = null;
							}
						}, 1000);
					}
				})
			},
			passLogn() {
				let a = {
					phone: this.userName,
					user_pass: this.userPowss,
				}
				Login_passlogin(a).then(res => {
					if (res.status == 1) {
						let key = this.$deepClone(this.$store.state.state_Sendkey.key)
						let iv = this.$deepClone(this.$store.state.state_Sendkey.iv)
						let c = JSON.parse(aes_decrypt(res.data, key, iv))
						this.$store.commit('UPDATA_login', c)
						this.logn = false

						if (this.checkauto) {
							this.autpLogin()
						}
					} else {
						this.$message.info(res.msg);

					}
				})

			},
			popno() {},
			subPowss() {
				// 确认两次密码
				if (this.firstPowss == this.endPowss) {
					if (this.firstPowss.length < 6) {
						this.$message.info('密码长度必须大于6位');
						return
					}
					let a = {
						password: this.firstPowss
					}
					Login_setpass(a).then(res => {
						if (res.status == 1) {
							this.isPoass = false
							this.logn = false
							this.$message.success(res.msg);
						} else {
							this.$message.info(res.msg);
						}
					})
				} else {
					if (this.firstPowss.length < 6) {
						this.$message.info('两次密码不一样');

					}
				}
			},
			dxLogn() {
				if (!this.checkauto2) {
					this.$message.info('请勾选协议');
					return
				}
				let a = {
					phone: this.userName,
					verity: this.userYzm,
					authCodeKey: this.userValue
				}
				Login_notelogin(a).then(res => {
					if (res.status == 1) {
						let key = this.$deepClone(this.$store.state.state_Sendkey.key)
						let iv = this.$deepClone(this.$store.state.state_Sendkey.iv)
						let c = JSON.parse(aes_decrypt(res.data, key, iv))

						this.$store.commit('UPDATA_login', c)
						this.logn = false
					} else if (res.status == 101) {
						let key = this.$deepClone(this.$store.state.state_Sendkey.key)
						let iv = this.$deepClone(this.$store.state.state_Sendkey.iv)
						let c = JSON.parse(aes_decrypt(res.data, key, iv))

						this.$store.commit('UPDATA_login', c)
						this.isPoass = true
					} else {
						this.$message.info(res.msg);

					}
				})
			},
			getdx3() {

				let a = {
					phone: this.zcphone,
					verity: this.verity3,
					authCodeKey: this.authCodeKey3,
					type: 'register' //类型：登录：login；找回密码
				}

				 

				Sendmsg_sendmsg(a).then(res => {
					 
					if (res.status == 1) {
						this.isYZM3 = false
						this.userValue33 = res.data.name
						this.djs = false
						this.count = 60;
						this.djs = false;
						this.timer = setInterval(() => {
							if (this.count > 0 && this.count <= 60) {
								this.count--;
							} else {
								this.djs = true;
								clearInterval(this.timer);
								this.timer = null;
							}
						}, 1000);


					}
				})
			},


			getdx() {

				let a = {
					phone: this.userName,
					verity: this.verity,
					authCodeKey: this.authCodeKey,
					type: 'login' //类型：登录：login；找回密码
				}
				Sendmsg_sendmsg(a).then(res => {
					if (res.status == 1) {
						this.isYZM = false
						this.userValue = res.data.name
						this.djs = false
						this.count = 60;
						this.djs = false;
						this.timer = setInterval(() => {
							if (this.count > 0 && this.count <= 60) {
								this.count--;
							} else {
								this.djs = true;
								clearInterval(this.timer);
								this.timer = null;
							}
						}, 1000);


					}
				})
			},
			getYZM2() {
				if (this.userName.length < 11) {
					return
				}
				this.SendIsPoass = true
				Sendmsg_imageyzm({}).then(res => {
					if (res.status == 1) {
						this.baseimg2 = res.data.base64
						this.authCodeKey2 = res.data.authCodeKey
					}
				})
			},
			getYZM5() {
				if (this.zcphone.length < 11) {
					this.$message.info('请先填写手机号');
					return
				}

				this.isYZM3 = true
				Sendmsg_imageyzm({}).then(res => {
					if (res.status == 1) {
						this.baseimg3 = res.data.base64
						this.authCodeKey = res.data.authCodeKey
					} else {
						this.$message.success(res.msg);
					}
				})

			},
			getYZM3() {
				if (this.zcphone.length < 11) {
					this.$message.info('请先填写手机号');
					return
				}

				this.isYZM3 = true
				Sendmsg_imageyzm({}).then(res => {
					if (res.status == 1) {
						this.baseimg3 = res.data.base64
						this.authCodeKey3 = res.data.authCodeKey
					} else {
						this.$message.success('请先填写手机号');
					}
				})
			},

			getYZM() {
				if (this.userName.length < 11) {
					this.$message.info('请先填写手机号');
					return
				}

				this.isYZM = true
				Sendmsg_imageyzm({}).then(res => {
					if (res.status == 1) {
						this.baseimg = res.data.base64
						this.authCodeKey = res.data.authCodeKey
					} else {
						this.$message.success('请先填写手机号');
					}
				})
			},
			onChange(e) {
				// 自动登录 
				this.checkauto = e.target.checked

			},
			onChange2(e) {
				// 自动登录 
				this.checkauto2 = e.target.checked

			},
			changPassword(ind) {
				if (ind == 1) {

					this.passwordChange = true

				} else {
					this.passwordChange = false
				}
			},
			passwordTf(ind) {
				this.passwordType = ind
			},
			passwordTf2(ind) {
				this.passwordType2 = ind
			},
			isYZMno() {

			},
			isYZMno3() {

			},
			agreementFun() {
				this.is_read = !this.is_read;
			},
			no() {

			},
			lognShow() {
				this.logn = true
			},
			lognHide() {
				if (this.zcishow) return
				if (this.isPoass) return
				this.logn = false
			},
			// 自动登录
			autpLogin() {
				var storage = window.localStorage;
				var data = {
					phone: this.userName,
					pass: this.userPowss
				};

				var setData = JSON.stringify(data);
				storage.setItem("userqc", setData)
			},

			changeSwiper: function(index) {
				// 点击scroll，将返回的参数赋值给currentTab
				if (this.currentTab == index) {
					return false;
				} else {
					this.currentTab = index;
				}
			}
		},

	}
</script>

<style lang="scss">
	.overflow-yy::-webkit-scrollbar{
		width: 0;
	}
	.headimg {
		img {
			width: 97.2px;
			height: 57.6px;
			filter: brightness(0) invert(1);
			margin-top: 4px;
		}
	
		;
		height: 88px;
		line-height: 88px;
		font-size: 24px;
		color: #fff;
		text-align: center;
		background: url('../assets/modal_header.58a834e.png') no-repeat;
		background-size: 100% 100%;
	
	}
	.swbody3 {
		div{
		text-align: right;
			width: 80px;
		}
		.ant-input {
			padding-left: 12px;
			flex: 1;
			// border-color: #cb000f;
			border-right-width: 1px !important;

		}

		.butt2 {
			img {
				height: 16px;
				width: 16px;
				cursor: pointer;
			}

			text-align: center;
			width:60px;
			margin-left: 8px;
		}

		.butt {
			border: 1px solid rgba(0, 0, 0, 0.25);
			cursor: pointer;
			line-height: 44px;
			text-align: center;
			width: 120px;
			height: 44px;
			margin-left: 8px;
		}

		.body {

			img {
				margin-left: 12px;
				width: 24px;
				height: 24px;
			}

			position: relative;
			font-size: 14px;
			display: inline-block;
			width: 48px;
			height: auto;
		}

		border: 1px solid rgba(0, 0, 0, 0);
		display: flex;
		align-items: center;
		margin-bottom: 16px;
	}

	.yzm {
		box-sizing: border-box;
		// background-color: white;
		transform: none;
		background: rgba(0, 0, 0, 0.4);
		position: fixed;
		outline: 0;
		overflow: auto;
		-webkit-overflow-scrolling: touch;
		z-index: 100001 !important;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		.bodyy {
			width: 300px;
			height: 150px;
			text-align: center;
		}
	}

	.currentTab1 {}

	.login-footer {
		img {
			padding-top: 6px;
			width: 44px;
			height: 44px;
			cursor: pointer;
		}

		display: flex;
		align-items: center;
		position: absolute;
		width: 100%;
		left: 0;
		bottom: 29px;
		justify-content: center;
		margin: 0;
	}

	.login-button {
		cursor: pointer;
		line-height: 40px;
		text-align: center;
		width: 100%;
		height: 40px;
		margin: 20px 0;
		color: white;
		background-color: #cb000f;
	}

	.swbody {
		border: 1px solid rgba(0, 0, 0, 0);
		display: flex;
		align-items: center;
		margin-bottom: 16px;

		.butt2 {
			img {
				height: 16px;
				width: 16px;
				cursor: pointer;
			}

			text-align: center;
			width:60px;

			margin-left: 8px;

		}

		.butt {
			border: 1px solid rgba(0, 0, 0, 0.25);
			cursor: pointer;
			line-height: 44px;
			text-align: center;
			width: 120px;
			height: 44px;
			margin-left: 8px;
		}

		.ant-input {
			padding-left: 12px;
			flex: 1;
			border-color: #cb000f;
			border-right-width: 1px !important;


			height: 44px;

		}

		.body {

			img {
				margin-left: 12px;
				width: 24px;
				height: 24px;
			}

			position: relative;
			font-size: 14px;
			display: inline-block;
			width: 48px;
			height: auto;
		}

	}

	@keyframes changebox {
		0% {
			opacity: 0;
		}

		100% {
			opacity: 1;
		}
	}

	.currentTab {
		height: 100%;

	}

	.currentTab:before {
		height: 4px;
		background-color: #cb000f;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		bottom: 0px;
		width: 50%;
		transition: all .3s;
		content: "";
		animation: changebox 0.5s ease 1;

	}

	.scroll-div {
		width: 100%;
		white-space: nowrap;
		border-bottom: 1ps list-separator(#list);
		display: flex;
		margin-bottom: 20px;

	}

	.body-div {
		font-size: 18px;
		color: #5a6274;
		line-height: 30px;
		position: relative;
		display: inline-block;
		box-sizing: border-box;
		height: 100%;
		padding: 12px 16px;
		text-decoration: none;
		cursor: pointer;
		transition: color .3s cubic-bezier(.645, .045, .355, 1);

	}

	.swiper {
		swiper-item {
			cursor: default;
		}

		padding-top: 20px;
		border-top: 1px solid rgba(0, 0, 0, .1);
		width: 370px;
		// height: 168px;
		// cursor: no-drop !important;

	}

	.swiper-scroll {
		height: 100%;
	}




	.modal-wrap {
		.modal-wrap-body {
			width: 440px;
			height: 460px;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);

			.login-main {
				background-color: white;
				padding: 0px 28px 24px;
				height: calc(100% - 126px);
				position: relative;
			}

			.headimg {
				img {
					width: 97.2px;
					height: 57.6px;
					filter: brightness(0) invert(1);
					margin-top: 4px;
				}

				;
				height: 88px;
				line-height: 88px;
				font-size: 24px;
				color: #fff;
				text-align: center;
				background: url('../assets/modal_header.58a834e.png') no-repeat;
				background-size: 100% 100%;

			}
		}

		background: rgba(0, 0, 0, 0.4);
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1000;
		overflow: auto;
		outline: 0;
		-webkit-overflow-scrolling: touch;
		z-index: 10000 !important;
	}

	.agreement {
		font-size: 26rpx;
		color: #999999;
		display: flex;
		// justify-content: center;
	}

	.agreement-info {
		cursor: pointer;
		color: #cb000f;
		font-weight: bold;
	}

	.agreement .unchecked {
		display: inline-block;
		width: 26rpx;
		height: 26rpx;
		border: 1px solid #999999;
		margin-top: 4rpx;
		margin-right: 8rpx;
	}

	.agreement .checkeds {
		display: inline-block;
		width: 26rpx;
		height: 26rpx;
		line-height: 26rpx;
		text-align: center;
		margin-top: 5rpx;
		margin-right: 8rpx;
		background-color: #cb000f;
		color: #FFFFFF;
	}
</style>
