//引入
import server from "./request.js"; //路劲自己修改
import Vue from 'vue'
import {
	rsaEncrypt,
	rsaDecrypt
} from '@/utils/rsa.js'
import {
	aes_encrypt,
	aes_decrypt
} from '@/utils/aes_endecrypt.js';
import md5 from '@/utils/md5';
import state from '@/store/index'
  
function $objKeySort(obj) { //排序的函数
	var newkey = Object.keys(obj).sort();
	//先用Object内置类的keys方法获取要排序对象的属性名，再利用Array原型上的sort方法对获取的属性名进行排序，newkey是一个数组
	var newObj = {}; //创建一个新的对象，用于存放排好序的键值对
	for (var i = 0; i < newkey.length; i++) { //遍历newkey数组
		newObj[newkey[i]] = obj[newkey[i]]; //向新创建的对象中按照排好的顺序依次增加键值对
	}
	return newObj; //返回排好序的新对象
} 
function $encryption(str) {
	var strInfo = str;
	strInfo.partne = '8BE71817AAE4890A1B3164C7C90F01A8';
	strInfo.timestamp = Math.round(new Date() / 1000);
	var data = $objKeySort(strInfo);
	var txt = "4B63EA40C901338ED9EB00FD23247B6EAEA6E02B349FFD2478B5263EA97B57AF";
	var newkey = Object.keys(data);
	for (var i = 0; i < newkey.length; i++) { //遍历newkey数组
		if (i == 0) {
			txt += newkey[i] + '=' + data[newkey[i]]
		} else {
			txt += '&' + newkey[i] + '=' + data[newkey[i]]
		}
	}
	txt += '4B63EA40C901338ED9EB00FD23247B6EAEA6E02B349FFD2478B5263EA97B57AF';
	var txtMd5 = md5(txt).toUpperCase();
	strInfo.sign = txtMd5;

	return strInfo
} 
function fun(params){  
	params = $encryption(params) 
	let bb = {}   
	let aesDa = aes_encrypt(JSON.stringify(params)) 
	bb['sendData'] = aesDa.enc
	let Sendkey = {
		key: aesDa.kStr,
		iv: aesDa.iStr,
		
	} 
 state.commit('UPDATA_state_Sendkey',Sendkey) 
 
	let aa = rsaEncrypt(JSON.stringify(Sendkey))
	let ttt = rsaEncrypt(state.state.islogin.userToken)
	
	let d = {
		aa:aa,
		b:bb,
		token:ttt
	}
	
	return d
}
 
   // 导航栏
export const headTitle = (params) => {  
		let a = fun(params) 
	return server({
		headers: { // 头部信息 
			'sendkey': a.aa, 
			'usertoken':a.token,
			},

		url: "/Api-Index-getmenu",
		method: "post", //后端给什么写什么
		data: a.b
	})
}

	// home页 请求
export const home_methods = (params) => {  
		let a = fun(params)
	return server({
		headers: { // 头部信息 
			'sendkey': a.aa, 
			'usertoken':a.token,
			}, 
		url: "/Api/Index/index",
		method: "post", //后端给什么写什么
		data: a.b
	})
}
// list页 kind
export const kind = (params) => {  
		let a = fun(params)
	return server({
		headers: { // 头部信息 
			'sendkey': a.aa, 
			'usertoken':a.token,
			}, 
		url: "/Api-Ranking-kind",
		method: "post", //后端给什么写什么
		data: a.b
	})
}
// list页 kind 下放的data
export const kind_data = (params) => {  
		let a = fun(params)
	return server({
		headers: { // 头部信息 
			'sendkey': a.aa, 
			'usertoken':a.token,
			}, 
		url: "/Api-Ranking-lists",
		method: "post", //后端给什么写什么
		data: a.b
	})
}
// Report页 kind
export const Report_kind = (params) => {  
		let a = fun(params)
	return server({
		headers: { // 头部信息 
			'sendkey': a.aa, 
			'usertoken':a.token,
			}, 
		url: "/Api-Report-kind",
		method: "post", //后端给什么写什么
		data: a.b
	})
}
// Report页 热门消息
export const Report_hoat = (params) => {  
		let a = fun(params)
	return server({
		headers: { // 头部信息 
			'sendkey': a.aa, 
			'usertoken':a.token,
			}, 
		url: "/Api-Report-hotlists",
		method: "post", //后端给什么写什么
		data: a.b
	})
} 
 
export const kind_sub = (params) => {  
		let a = fun(params)
	return server({
		headers: { // 头部信息 
			'sendkey': a.aa, 
			'usertoken':a.token,
			}, 
		url: "/Api-Report-lists",
		method: "post", //后端给什么写什么
		data: a.b
	})
}
export const page_change = (params) => {  
		let a = fun(params)
	return server({
		headers: { // 头部信息 
			'sendkey': a.aa, 
			'usertoken':a.token,
			}, 
		url: "/Api-Report-lists",
		method: "post", //后端给什么写什么
		data: a.b
	})
}
// /Api-News-kind  行业资讯分类
export const info_News_kind = (params) => {  
		let a = fun(params)
	return server({
		headers: { // 头部信息 
			'sendkey': a.aa, 
			'usertoken':a.token,
			}, 
		url: "/Api-News-kind",
		method: "post", //后端给什么写什么
		data: a.b
	})
}

// /Api-News-lists 行业资讯分类kindsIndCange点击事件
export const info_News_lists = (params) => {  
		let a = fun(params)
	return server({
		headers: { // 头部信息 
			'sendkey': a.aa, 
			'usertoken':a.token,
			}, 
		url: "/Api-News-lists",
		method: "post", //后端给什么写什么
		data: a.b
	})
}

// /Api-News-hotspot 热点资讯列表
// type  状态1：今天；2：近三天；3：本月
export const News_hotspot = (params) => {  
		let a = fun(params)
	return server({
		headers: { // 头部信息 
			'sendkey': a.aa, 
			'usertoken':a.token,
			}, 
		url: "/Api-News-hotspot",
		method: "post", //后端给什么写什么
		data: a.b
	})
}
// /Api-News-newsfast 快讯列表
// page
// pageSize
export const News_newsfast = (params) => {  
		let a = fun(params)
	return server({
		headers: { // 头部信息 
			'sendkey': a.aa, 
			'usertoken':a.token,
			}, 
		url: "/Api-News-newsfast",
		method: "post", //后端给什么写什么
		data: a.b
	})
}
// /Api-Aboutus-index 关于我们
export const Aboutus_index = (params) => {  
		let a = fun(params)
	return server({
		headers: { // 头部信息 
			'sendkey': a.aa, 
			'usertoken':a.token,
			}, 
		url: "/Api-Aboutus-index",
		method: "post", //后端给什么写什么
		data: a.b
	})
}
// 供应链数据 Api-Supplychain-lists

export const Aboutus_lists = (params) => {  
		let a = fun(params)
	return server({
		headers: { // 头部信息 
			'sendkey': a.aa, 
			'usertoken':a.token,
			}, 
		url: "/Api-Supplychain-lists",
		method: "post", //后端给什么写什么
		data: a.b
	})
}
export const Supplychain_kind = (params) => {  
		let a = fun(params)
	return server({
		headers: { // 头部信息 
			'sendkey': a.aa, 
			'usertoken':a.token,
			}, 
		url: "/Api-Supplychain-kind",
		method: "post", //后端给什么写什么
		data: a.b
	})
}
export const Supplychain_lists = (params) => {  
		let a = fun(params)
	return server({
		headers: { // 头部信息 
			'sendkey': a.aa, 
			'usertoken':a.token,
			}, 
		url: "/Api-Supplychain-sjjslists",
		method: "post", //后端给什么写什么
		data: a.b
	})
}
export const Supplychain_content = (params) => {  
		let a = fun(params)
	return server({
		headers: { // 头部信息 
			'sendkey': a.aa, 
			'usertoken':a.token,
			}, 
		url: "/Api-Supplychain-content",
		method: "post", //后端给什么写什么
		data: a.b
	})
}


// 数据超市/Api-Index-sjcsmenu 菜单
export const Index_sjcsmenu = (params) => {  
		let a = fun(params)
	return server({
		headers: { // 头部信息 
			'sendkey': a.aa, 
			'usertoken':a.token,
			}, 
		url: "/Api-Index-sjcsmenu",
		method: "post", //后端给什么写什么
		data: a.b
	})
}

// 数据超市/Api-Index-sjcsmenu 菜单
export const Pmcountryretail_getwhere = (params) => {  
		let a = fun(params)
	return server({
		headers: { // 头部信息 
			'sendkey': a.aa, 
			'usertoken':a.token,
			}, 
		url: "/Api-Pmcountryretail-getwhere",
		method: "post", //后端给什么写什么
		data: a.b
	})
}


// /Api-Pmcountryretail-lists  查询列表全国销量
export const Pmcountryretail_lists = (params) => {  
		let a = fun(params)
	return server({
		headers: { // 头部信息 
			'sendkey': a.aa, 
			'usertoken':a.token,
			}, 
		url: "/Api-Pmcountryretail-lists",
		method: "post", //后端给什么写什么
		data: a.b
	})
}

// Api-Pmmerchantsales-getwhere 查询列表全国销量
export const Pmmerchantsales_getwhere = (params) => {  
		let a = fun(params)
	return server({
		headers: { // 头部信息 
			'sendkey': a.aa, 
			'usertoken':a.token,
			}, 
		url: "/Api-Pmmerchantsales-getwhere",
		method: "post", //后端给什么写什么
		data: a.b
	})
}
// Api-Pmmerchantsales-getwhere 查询列表全国销量
export const Pmmerchantsales_lists = (params) => {  
		let a = fun(params)
	return server({
		headers: { // 头部信息 
			'sendkey': a.aa, 
			'usertoken':a.token,
			}, 
		url: "/Api-Pmmerchantsales-lists",
		method: "post", //后端给什么写什么
		data: a.b
	})
}

// 
export const Pmcountryinventory_getwhere = (params) => {  
		let a = fun(params)
	return server({
		headers: { // 头部信息 
			'sendkey': a.aa, 
			'usertoken':a.token,
			}, 
		url: "/Api-Pmcountryinventory-getwhere",
		method: "post", //后端给什么写什么
		data: a.b
	})
}

export const Pmcountryinventory_lists = (params) => {  
		let a = fun(params)
	return server({
		headers: { // 头部信息 
			'sendkey': a.aa, 
			'usertoken':a.token,
			}, 
		url: "/Api-Pmcountryinventory-lists",
		method: "post", //后端给什么写什么
		data: a.b
	})
}
   //		/Api-Pmcountrywholesale-getwhere   //国内批发量搜索条件

export const Pmcountrywholesale_getwhere = (params) => {  
		let a = fun(params)
	return server({
		headers: { // 头部信息 
			'sendkey': a.aa, 
			'usertoken':a.token,
			}, 
		url: "/Api-Pmcountrywholesale-getwhere",
		method: "post", //后端给什么写什么
		data: a.b
	})
}
export const Pmcountrywholesale_lists = (params) => {  
		let a = fun(params)
	return server({
		headers: { // 头部信息 
			'sendkey': a.aa, 
			'usertoken':a.token,
			}, 
		url: "/Api-Pmcountrywholesale-lists",
		method: "post", //后端给什么写什么
		data: a.b
	})
}

// /Api-Pmcountryoutput-getwhere   //国内产量搜索条件


export const Pmcountryoutput_getwhere = (params) => {  
	
		let a = fun(params)
	return server({
		headers: { // 头部信息 
			'sendkey': a.aa, 
			'usertoken':a.token,
			}, 
		url: "/Api-Pmcountryoutput-getwhere",
		method: "post", //后端给什么写什么
		data: a.b
	})
}

export const Pmcountryoutput_lists = (params) => {  
		let a = fun(params)
	return server({
		headers: { // 头部信息 
			'sendkey': a.aa, 
			'usertoken':a.token,
			}, 
		url: "/Api-Pmcountryoutput-lists",
		method: "post", //后端给什么写什么
		data: a.b
	})
}

// /Api-Pmmerchantinventory-getwhere   //商用车保有量搜索条件
export const Pmmerchantinventory_getwhere = (params) => {  
		let a = fun(params)
	return server({
		headers: { // 头部信息 
			'sendkey': a.aa, 
			'usertoken':a.token,
			}, 
		url: "/Api-Pmmerchantinventory-getwhere",
		method: "post", //后端给什么写什么
		data: a.b
	})
}
export const Pmmerchantinventory_lists = (params) => {  
		let a = fun(params)
	return server({
		headers: { // 头部信息 
			'sendkey': a.aa,
			 'usertoken':a.token,
			}, 
		url: "/Api-Pmmerchantinventory-lists",
		method: "post", //后端给什么写什么
		data: a.b
	})
}

// /Api-Neretailsales-getwhere   //新能源零售销量搜索条件
export const Neretailsales_getwhere = (params) => {  
		let a = fun(params)
	return server({
		headers: { // 头部信息 
			'sendkey': a.aa, 
			}, 
		url: "/Api-Neretailsales-getwhere",
		method: "post", //后端给什么写什么
		data: a.b
	})
}
export const Neretailsales_lists = (params) => {  
		let a = fun(params)
	return server({
		headers: { // 头部信息 
			'sendkey': a.aa, 
			'usertoken':a.token,
			}, 
		url: "/Api-Neretailsales-lists",
		method: "post", //后端给什么写什么
		data: a.b
	})
}


// /Api-Neretailinventory-getwhere   //新能源保有量搜索条件
export const Neretailinventory_getwhere = (params) => {  
		let a = fun(params)
	return server({
		headers: { // 头部信息 
			'sendkey': a.aa, 
			'usertoken':a.token,
			}, 
		url: "/Api-Neretailinventory-getwhere",
		method: "post", //后端给什么写什么
		data: a.b
	})
}
export const Neretailinventory_lists = (params) => {  
		let a = fun(params)
	return server({
		headers: { // 头部信息 
			'sendkey': a.aa, 
			'usertoken':a.token,
			}, 
		url: "/Api-Neretailinventory-lists",
		method: "post", //后端给什么写什么
		data: a.b
	})
}

// 图片验证码
export const Sendmsg_imageyzm = (params) => {  
		let a = fun(params)
	return server({
		headers: { // 头部信息 
			'sendkey': a.aa, 'usertoken':a.token,
			}, 
		url: "/Api-Sendmsg-imageyzm",
		method: "post", //后端给什么写什么
		data: a.b
	})
}
// /Api-Sendmsg-sendmsg  //短信验证码
export const Sendmsg_sendmsg = (params) => {  
		let a = fun(params)
	return server({
		headers: { // 头部信息 
			'sendkey': a.aa, 'usertoken':a.token,
			}, 
		url: "/Api-Sendmsg-sendmsg",
		method: "post", //后端给什么写什么
		data: a.b
	})
}

// /Api-Login-notelogin //短信验证码登 
export const Login_notelogin = (params) => {  
		let a = fun(params)
		 
	return server({ 
		// a:a
		headers: { // 头部信息 
			'sendkey': a.aa, 'usertoken':a.token,
			}, 
		url: "/Api-Login-notelogin",
		method: "post", //后端给什么写什么
		data: a.b
	})
}
// /Api-Login-setpass //设置密码
 
export const Login_setpass = (params) => {  
		let a = fun(params)
		 
	return server({ 
		// a:a
		headers: { // 头部信息 
		'usertoken':a.token,
			'sendkey': a.aa, 
			}, 
		url: "/Api-Login-setpass",
		method: "post", //后端给什么写什么
		data: a.b
	})
}
// /Api-Login-passlogin //密码登录

export const Login_passlogin = (params) => {  
		let a = fun(params) 
	return server({  
		headers: { // 头部信息 
		    'usertoken':a.token,
			'sendkey': a.aa, 
			}, 
		url: "/Api-Login-passlogin",
		method: "post", //后端给什么写什么
		data: a.b
	})
}

// Api-Login-getback //找回密码 

export const Login_getback = (params) => {  
		let a = fun(params) 
	return server({  
		headers: { // 头部信息 
			'sendkey': a.aa, 'usertoken':a.token,
			}, 
		url: "/Api-Login-getback",
		method: "post", //后端给什么写什么
		data: a.b
	})
}
export const Agreemen_lists = (params) => {  
		let a = fun(params) 
	return server({  
		headers: { // 头部信息 
			'sendkey': a.aa, 'usertoken':a.token,
			}, 
		url: "/Api-Agreement-lists",
		method: "post", //后端给什么写什么
		data: a.b
	})
}
 
export const Agreement_content = (params) => {  
		let a = fun(params) 
	return server({  
		headers: { // 头部信息 
			'sendkey': a.aa, 'usertoken':a.token,
			}, 
		url: "/Api-Agreement-content",
		method: "post", //后端给什么写什么
		data: a.b
	})
}

 
export const User_index = (params) => {  
		let a = fun(params) 
	return server({  
		headers: { // 头部信息 
			'sendkey': a.aa, 'usertoken':a.token,
			}, 
		url: "/Api-User-index",
		method: "post", //后端给什么写什么
		data: a.b
	})
}
export const User_upusername = (params) => {  
		let a = fun(params) 
	return server({  
		headers: { // 头部信息 
			'sendkey': a.aa, 'usertoken':a.token,
			}, 
		url: "/Api-User-upusername",
		method: "post", //后端给什么写什么
		data: a.b
	})
}
export const User_upnickname = (params) => {  
		let a = fun(params) 
	return server({  
		headers: { // 头部信息 
			'sendkey': a.aa, 'usertoken':a.token,
			}, 
		url: "/Api-User-upnickname",
		method: "post", //后端给什么写什么
		data: a.b
	})
}

export const User_upuserpass = (params) => {  
		let a = fun(params) 
	return server({  
		headers: { // 头部信息 
			'sendkey': a.aa, 
			'usertoken':a.token,
			}, 
		url: "/Api-User-upuserpass",
		method: "post", //后端给什么写什么
		data: a.b
	})
}

export const User_upemail = (params) => {  
		let a = fun(params) 
	return server({  
		headers: { // 头部信息 
			'sendkey': a.aa, 
			'usertoken':a.token,
			}, 
		url: "/Api-User-upemail",
		method: "post", //后端给什么写什么
		data: a.b
	})
}

 
 export const User_upwechat = (params) => {  
 		let a = fun(params) 
 	return server({  
 		headers: { // 头部信息 
 			'sendkey': a.aa, 
 			'usertoken':a.token,
 			}, 
 		url: "/Api-User-upwechat",
 		method: "post", //后端给什么写什么
 		data: a.b
 	})
 }
 
 
 export const User_company = (params) => {
 		let a = fun(params) 
 	return server({  
 		headers: { // 头部信息 
 			'sendkey': a.aa, 
 			'usertoken':a.token,
 			}, 
 		url: "/Api-User-company",
 		method: "post", //后端给什么写什么
 		data: a.b
 	})
 }
 export const Newsr_content = (params) => {
 		let a = fun(params) 
 	return server({  
 		headers: { // 头部信息 
 			'sendkey': a.aa, 
 			'usertoken':a.token,
 			}, 
 		url: "/Api-News-content",
 		method: "post", //后端给什么写什么
 		data: a.b
 	})
 }
  
  export const Report_content = (params) => {
  		let a = fun(params) 
  	return server({  
  		headers: { // 头部信息 
  			'sendkey': a.aa, 
  			'usertoken':a.token,
  			}, 
  		url: "/Api-Report-content",
  		method: "post", //后端给什么写什么
  		data: a.b
  	})
  }
  
  
  export const Report_ordercontent = (params) => {
  		let a = fun(params) 
  	return server({  
  		headers: { // 头部信息 
  			'sendkey': a.aa, 
  			'usertoken':a.token,
  			}, 
  		url: "/Api-Report-ordercontent",
  		method: "post", //后端给什么写什么
  		data: a.b
  	})
  }
  export const Agreement_buylist = (params) => {
  		let a = fun(params) 
  	return server({  
  		headers: { // 头部信息 
  			'sendkey': a.aa, 
  			'usertoken':a.token,
  			}, 
  		url: "/Api-Agreement-buylist",
  		method: "post", //后端给什么写什么
  		data: a.b
  	})
  }
  
  export const Reportorder_addorder = (params) => {
  		let a = fun(params) 
  	return server({  
  		headers: { // 头部信息 
  			'sendkey': a.aa, 
  			'usertoken':a.token,
  			}, 
  		url: "/Api-Reportorder-addorder",
  		method: "post", //后端给什么写什么
  		data: a.b
  	})
  }
  
  // 验证订单是否支付
  
  export const Reportorder_verifyorder = (params) => {
  		let a = fun(params) 
  	return server({  
  		headers: { // 头部信息 
  			'sendkey': a.aa, 
  			'usertoken':a.token,
  			}, 
  		url: "/Api-Reportorder-verifyorder",
  		method: "post", //后端给什么写什么
  		data: a.b
  	})
  }
  

  export const User_download = (params) => {
  		let a = fun(params) 
  	return server({  
  		headers: { // 头部信息 
  			'sendkey': a.aa, 
  			'usertoken':a.token,
  			}, 
  		url: "/Api-User-download",
  		method: "post", //后端给什么写什么
  		data: a.b
  	})
  }
  
  export const User_reportorderlists = (params) => {
  		let a = fun(params) 
  	return server({  
  		headers: { // 头部信息 
  			'sendkey': a.aa, 
  			'usertoken':a.token,
  			}, 
  		url: "/Api-User-reportorderlists",
  		method: "post", //后端给什么写什么
  		data: a.b
  	})
  }
  
  export const Reportorder_qxorder = (params) => {
  		let a = fun(params) 
  	return server({  
  		headers: { // 头部信息 
  			'sendkey': a.aa, 
  			'usertoken':a.token,
  			}, 
  		url: "/Api-Reportorder-qxorder",
  		method: "post", //后端给什么写什么
  		data: a.b
  	})
  }
  
 export const Reportorder_anewaddorder = (params) => {
 		let a = fun(params) 
 	return server({  
 		headers: { // 头部信息 
 			'sendkey': a.aa, 
 			'usertoken':a.token,
 			}, 
 		url: "/Api-Reportorder-anewaddorder",
 		method: "post", //后端给什么写什么
 		data: a.b
 	})
 }
  
  export const down_file2 = (params) => {
  		let a = fun(params) 
  	return server({  
  		headers: { // 头部信息 
  			'sendkey': a.aa, 
  			'usertoken':a.token,
  			}, 
  		url: "/Api-User-download",
  		method: "post", //后端给什么写什么
  		data: a.b,
  		responseType: 'blob' //返回是个文件
  	})
  }
  export const down_file = (params) => {
  		let a = fun(params) 
  	return server({  
  		headers: { // 头部信息 
  			'sendkey': a.aa, 
  			'usertoken':a.token,
  			}, 
  		url: "/Api-User-download",
  		method: "post", //后端给什么写什么
  		data: a.b,
		 
  	})
  }
   export const Login_registere = (params) => {
   		let a = fun(params) 
   	return server({  
   		headers: { // 头部信息 
   			'sendkey': a.aa, 
   			'usertoken':a.token,
   			}, 
   		url: "/Api-Login-register",
   		method: "post", //后端给什么写什么
   		data: a.b, 
   	})
   }
  
  // axios({
  //     method: "POST",
  //     url: url,
  //     data: data,
  //     params: data,
  //     responseType: 'blob' //返回是个文件
  //   }).then(response => {
  //     download(response, data) //then直接下载，方法在下边
  //   })